import {useEffect, useState} from 'react' 
import { Link,useNavigate} from "react-router-dom";
import Button from "../components/Button";

import BreadCrumb from '../components/Breadcrumb'
import Text from '../components/Text'
import Newsletter from '../components/Newsletter'
import Input from '../components/Input'
import axios from "axios"
import { config } from '../config';
import { ToastContainer, toast } from 'react-toastify';

import { useFormik } from 'formik';
import { resetSchema } from '../components/Schemas'
import PasswordChecklist from "react-password-checklist"

function ChangePassword() {

    const navigate = useNavigate()

    const initialValues = {
        verifyotp: null,
        Password: "",
        ConfirmPassword:""
    }

    const [passType, setPassType] = useState("password");
    const [icoSrc, setIcoSrc] = useState("eyeHide");
    const [email, setemail] = useState("");
    const [PassValid, setPassValid] = useState(false);
    const [error, seterror] = useState(false);

    const { values, errors, handleBlur, touched, handleChange, handleSubmit } = useFormik({
        initialValues: initialValues,
        validationSchema: resetSchema,
        onSubmit: (values) => {

            if(!PassValid){
                console.log(PassValid)
                seterror(true)
            }
            else{
                console.log(PassValid)
                seterror(false)
                resetPassword(values)
            }

        }
    })

    console.log('ana',values)

    const showHideFun = () =>{
        if (passType === "password" && icoSrc=== "eyeHide"){
            setPassType("text")
            setIcoSrc("eyeShow")
        }else{
            setPassType("password")
            setIcoSrc("eyeHide")
        }
    } 
// -----------------------------resetpassword API------------------------------------
    const resetPassword = async (value) => {
        const email = localStorage.getItem('email',value.email );
        let data = {
          email : email,
          otp:value.verifyotp,
          password:value.Password
        }
        try {
            const response = await axios.post(`${config.siteURL}user/resetPassword/`, data);
            console.log(response , "gggg")
            if(response){
                console.log(response.data.msg , "response")
               toast(response.data.msg)
                navigate("/login")
            }
            
        } catch (error) {
            toast(error.response.data.msg)
        }

    }

    useEffect(()=>{
        const mail = localStorage.getItem('email')
       setemail(mail)
    })
    
    const [passType2, setPassType2] = useState("password");
    const [icoSrc2, setIcoSrc2] = useState("eyeHide");

    const showHideFun2 = () =>{
        if (passType2 === "password" && icoSrc=== "eyeHide"){
            setPassType2("text")
            setIcoSrc2("eyeShow")
        }else{
            setPassType2("password")
            setIcoSrc2("eyeHide")
        }
    } 

    return (
        <>
          <ToastContainer/>
            <BreadCrumb 
                customClass="mr-5 my-2"
                menuActive="Contact"
                align="end"
                color="black"
            />
            <section className='changePassword-form py-5'>
                <div className='container'>
                    <div className="row">
                        <div className="col-lg-6 mx-auto">
                        <div className="bg-primary p-5 radius-5">
                            <Text
                                customClass="text-weight-600 d-inline text-uppercase"
                                text="Change Password"
                                variant="36"
                                color="white"
                            />
                            <div className='headingDivider bg-white mb-5 '></div>
                            <form className="row common-form common-form-borderless common-form-bg">
                                <div className="col-lg-12 mb-4 pr-0">
                                    <label className='text-weight-600' for="email">Verify OTP</label>
                                    <input className="box-shadow mt-2" id="otp" type="text" placeholder="Verify OTP" 
                                    value={values.verifyotp}
                                    name="verifyotp" onChange={handleChange} onBlur={handleBlur} />
                                    {errors.verifyotp && touched.verifyotp ? <span style={{ fontWeight: 'bold', color: 'blue', }}>{errors.verifyotp}</span> : null}
                                </div>
                                <div className="col-lg-6 pr-0">
                                    <label className='text-weight-600 d-block mb-2' for="pass">Create Password</label>
                                    <Input
                                        id="pass"
                                        type={passType}
                                        placeholder="password"
                                        name="Password"
                                        childClass="right-0 pr-2"
                                        customClass={``}
                                        value={values.Password}
                                        onChange={handleChange}
                                    >
                                        {/* <div className={`showHideEyes@`}  > */}
                                            <img onClick={showHideFun} className="eyeIco" src={`/imgs/${icoSrc}.svg`} />
                                        {/* </div> */}
                                    </Input>
                                    <PasswordChecklist
                                            rules={["minLength", "specialChar", "number", "capital"]}
                                            minLength={8}
                                            value={values.Password}
                                            messages={{
                                                minLength: "Minimum 8 character",
                                                specialChar: "Special character should be mandatory ",
                                                number: "Number",
                                                capital: "Captial Letter",
                                            }}
                                            onChange={(isValid) => {setPassValid(isValid)}}
                                        />

                                        {/* {errors.Password && touched.Password ? <span style={{ fontWeight: 'bold', color: 'blue', }}>{errors.Password}</span> : null} */}
                                </div>
                                <div className="col-lg-6  ">
                                    <label className='text-weight-600 d-block mb-2' for="pass">Confirm Password</label>
                                    <Input
                                        id="pass"
                                        type={passType2}
                                        placeholder="password"
                                        childClass="right-0 pr-2"
                                        customClass={``}
                                        name={"ConfirmPassword"}
                                        value={values.ConfirmPassword}
                                        onChange={handleChange}
                                        handleBlur={handleBlur}
                                    >
                                        <img onClick={showHideFun2} className="eyeIco" src={`/imgs/${icoSrc2}.svg`} />
                                    </Input>
                                    {errors.ConfirmPassword && touched.ConfirmPassword ? <span style={{ fontWeight: 'bold', color: 'blue', }}>{errors.ConfirmPassword}</span> : null}
                                </div>
                                <div className="col-md-12 col-12 ml-auto mt-4">
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <Link to="" className='bg-none text-white text-underline-none text-title-18'><i class="fa fa-long-arrow-left" aria-hidden="true"></i> &nbsp; Back</Link>
                                        <Button
                                            customClass=" text-weight-600"
                                            text="Change Password"
                                            btnBg="white"
                                            color="black"
                                            btnOnClick={handleSubmit}
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                           
                        </div>
                    </div>
                </div>
            </section>

            {/* ======= NewsLetter ======== */}

            <div className="container py-4 mt-4">
                <div  style={{borderTop: "1px solid #ddd"}}></div>
                <div className="row ">
                    <div className={` col-md-6 text-center mx-auto my-4`}>
                        <Newsletter />
                    </div>
                </div>
            </div>

            {/* ======= NewsLetter ======== */}

        </>
    );
}

export default ChangePassword