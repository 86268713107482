import React, { children } from 'react';
import './style.css'

function InputGroup(props) {
    return (
        <>
            {/* {console.log('ana', props)} */}
            <div className={`input-group position-relative  ${props.customClass} ${props.align}`}>
                <input id={props.id} type={props.type} className={`w-100 ${props.inputClass}`} placeholder={props.placeholder} value={props.value} name={props.name} onChange={props.onChange} autocomplete="new-password"/>
                <span
                    className={`input-group-children position-absolute ${props.childClass} `}
                >
                    {props.children}
                </span>
            </div>
        </>
    )
}
export default InputGroup