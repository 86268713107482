import { useState, useEffect } from 'react'
import { Link,useNavigate } from "react-router-dom";
import Button from "../components/Button";

import { loginSchema } from '../components/Schemas'
import { useFormik } from 'formik';
import HeaderImg from '../components/HeaderImg'
import BreadCrumb from '../components/Breadcrumb'
import Text from '../components/Text'
import Card from '../components/ImgCard'
import Newsletter from '../components/Newsletter'
import Input from '../components/Input'
import { ToastContainer, toast } from 'react-toastify';
import { config } from '../config';
import axios from "axios";

function Login() {

    const navigate = useNavigate()

    const initialValues = {
        email: "",
        Password: "",
    }

    const location = window.location.href;
    const position = location.search("tkn");
    const subStr = location.substring(position);
    const domainPosition = subStr.search("=");
    const companyDomain = subStr.substring(domainPosition + 1);
    const check = location.includes('tkn')


// ----------------------------verify email----------------------------------
    const verifyEmail = async () => {
        let data = {
            otp: companyDomain
        }
        try {
            const response = await axios.post(`${config.siteURL}user/verify`, data);
            toast("Successfully verified your Account")
        } catch (error) {
            // toast("Already verify your Account")
        }
    }
    // ----------------------------login----------------------------------
    const login = async (value) => {
        let data = value
        try {
            const response = await axios.post(`${config.siteURL}user/login`, data);
            console.log(response.data.data.response,"deepali")
            toast(response.data.data.message)
            localStorage.setItem('firstName',response.data.data.response.firstName );
            localStorage.setItem('lastName',response.data.data.response.lastName );
            localStorage.setItem('email',response.data.data.response.email);
            navigate("/")
        } catch (error) {
            toast(error.response.data.data.message )
        }
    }

    useEffect(() => {
        if (check) {
            verifyEmail()
        }
    });

    const { values, errors, handleBlur, touched, handleChange, handleSubmit } = useFormik({
        initialValues: initialValues,
        validationSchema: loginSchema,
        onSubmit: (values) => {
            login(values)
        }
    })

    const [passType, setPassType] = useState("password");
    const [icoSrc, setIcoSrc] = useState("eyeHide");

    const showHideFun = () => {
        if (passType === "password" && icoSrc === "eyeHide") {
            setPassType("text")
            setIcoSrc("eyeShow")
        } else {
            setPassType("password")
            setIcoSrc("eyeHide")
        }
    }

    return (
        <>

            <ToastContainer />
            <BreadCrumb
                customClass="mr-5 my-2"
                menuActive="Contact"
                align="end"
                color="black"
            />

            <section className='loginSignup-form py-5 '>
                <div className='container'>
                    <div className="row">
                        <div className="col-lg-6 mx-auto">
                            <div className="bg-white loginSignup-form-box p-5  my-5 radius-5 box-shadow">
                                <div className="row mb-4 px-0 px-xl-5">
                                    <div className="col-md-12 text-left">
                                        <Text
                                            customClass="text-weight-700"
                                            text="Welcome Back"
                                            variant="36"
                                            color="primary"
                                        />
                                        <div className="login-form-social d-flex mt-4 mb-4">
                                            <div className="login-form-social-ico radius-full d-flex align-items-center justify-content-center bg-black mr-2">
                                                <img src="/imgs/g.svg" />
                                            </div>
                                            <div className="login-form-social-ico radius-full d-flex align-items-center justify-content-center bg-black mr-2">
                                                <img src="/imgs/fb2.svg" />
                                            </div>
                                            <div className="login-form-social-ico radius-full d-flex align-items-center justify-content-center bg-black">
                                                <img src="/imgs/tw2.svg" />
                                            </div>
                                        </div>
                                        <Text
                                            customClass="text-weight-800 text-uppercase"
                                            text="Login to your account"
                                            variant="26"
                                            color="black"
                                        />
                                        <div className='headingDivider bg-primary'></div>
                                    </div>
                                </div>
                                <form className="row common-form common-form-borderless common-form-bg px-0 px-xl-5">
                                    <div className="col-lg-12 mb-3 ">
                                        <label className='text-weight-600' for="email">Email</label>
                                        <input className="box-shadow mt-2" id="email" type="text" placeholder="Email" value={values.email}
                                            name="email" onChange={handleChange} onBlur={handleBlur} />
                                        {errors.email && touched.email ? <span style={{ fontWeight: 'bold', color: 'red', }}>{errors.email}</span> : null}
                                    </div>
                                    <div className="col-lg-12 mb-3 ">
                                        <label className='text-weight-600 d-block mb-2' for="pass">Password</label>
                                        <Input
                                            id="pass"
                                            type={passType}
                                            placeholder="password"
                                            childClass="showHide"
                                            customClass={``}
                                            value={values.Password}
                                            name="Password"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        >
                                            <div className={`showHideEyes`} onClick={showHideFun}>
                                                <img className="eyeIco" src={`/imgs/${icoSrc}.svg`} />
                                            </div>
                                        </Input>
                                        {errors.Password && touched.Password ? <span style={{ fontWeight: 'bold', color: 'red', }}>{errors.Password}</span> : null}
                                    </div>
                                    <div className="col-lg-12 mb-2 text-right">
                                        <Link to="/otp-verify" className='text-blue text-weight-600'>Forget Password</Link>
                                    </div>
                                    <div className="col-lg-12 my-3">
                                        <div className='row align-items-center justify-content-between'>
                                            <div className='col-md-8'>
                                                <Text
                                                    customClass="text-weight-700 d-inline"
                                                    text="Don't have an account?"
                                                    variant="16"
                                                    color="black"
                                                />
                                                <Link to="/signup" className='text-blue text-weight-600'> Register</Link>
                                            </div>
                                            <div className="col-lg-4 ">
                                                <Button
                                                    customClass="w-100"
                                                    text="Login"
                                                    btnBg="primary"
                                                    btnOnClick={handleSubmit}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* ======= NewsLetter ======== */}

            <div className="container py-4 mt-4">
                <div style={{ borderTop: "1px solid #ddd" }}></div>
                <div className="row ">
                    <div className={` col-md-6 text-center mx-auto my-4`}>
                        <Newsletter />
                    </div>
                </div>
            </div>

            {/* ======= NewsLetter ======== */}

        </>
    );
}

export default Login