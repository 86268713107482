import React from "react";
// import PropTypes from 'prop-types'

import './style.css'

function BlogCard(props) {

  return (

    <div id={props.id} className={`BlogCard overflow-hidden position-relative overlay-${props.overlay} ${props.customClass}`}>
      <img src={props.imgSrc} className="w-100 BlogCard-img" />

      <div className={`BlogCard-date position-absolute text-white ${props.childClass}`}>
          <div className="BlogCard-date-dd ">{props.dd}</div>
          <div className="BlogCard-date-mm bg-primary text-white">{props.mm}</div>
      </div>

      {props?.children
        ? <div className={`BlogCard-child px-3 ${props.childClass} text-left mt-4`}>
          {props.children}
        </div>
        : ""
      }

    </div>

  );
}

export default BlogCard;
