import { Link,useNavigate} from "react-router-dom";
import { Row, Col, Alert, Container, Form, label } from "react-bootstrap"
import Style from './AdminStyle';
import logo from "../../assets/images/LogoLg.svg";

import { useState } from "react"
import { ToastContainer, toast } from 'react-toastify';
import { useFormik } from 'formik';
import { adminLoginSchema } from "../Schemas";
import { config } from "../../config"
import axios from "axios";

function Login() {

  const navigate = useNavigate()

  const initialValues = {
    email: "",
    Password: "",
  }


  const { values, errors, handleBlur, touched, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: adminLoginSchema,
    onSubmit: (values) => {
      console.log("vvvvvvv")
      adminLogin(values)
    }
  })

  const adminLogin = async (value) => {
    let data = {
      email:value.email,
      password:value.Password
    }

    const saveSpinner = document.getElementById("saveSpinner");
    const failedSaveErrorIcon = document.getElementById(
      "failedSaveErrorIcon"
    );

    const nexticon = document.getElementById("nexticon");

    saveSpinner.style.display = "inline-block";
    failedSaveErrorIcon.style.display = "none";

    

    try {
      const response = await axios.post(`${config.siteURL}admin/login`, data);
      saveSpinner.style.display = "none";
      failedSaveErrorIcon.style.display = "none";
      let detail = response.data.data.user
      toast(response.data.data.message)
      localStorage.setItem('userDetails', JSON.stringify(detail));
      navigate("/admin/dashboard")
    }
    catch (error) {
      saveSpinner.style.display = "none";
      failedSaveErrorIcon.style.display = "inline-block";
      toast(error.response.data.data.message)
    }
  }

  const [passType, setPassType] = useState("password");
  const [icoSrc, setIcoSrc] = useState("eyeHide");
  const showHideFun = () => {
    if (passType === "password" && icoSrc === "eyeHide") {
      setPassType("text")
      setIcoSrc("eyeShow")
    } else {
      setPassType("password")
      setIcoSrc("eyeHide")
    }
  }

  return (
    <>
      <ToastContainer />
      <Style />
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4} md={5} className="col-xxl-3">
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5 text-center">
                      <Link to="/merchant/login" className="d-block auth-logo">
                        <img src={logo} alt="" height="53" />
                      </Link>
                    </div>
                    <div className="auth-content my-auto">
                      <div className="text-center">
                        <h5 className="mb-0">Welcome Back !</h5>
                        <p className="text-muted mt-2">Sign in to continue to R D Digital</p>
                      </div>
                      <Form className="formStyle mt-4 pt-2">
                        <div className="mb-4">
                          <label for="firstNameFld" class="form-label">Email*</label>
                          <input type="email" class="form-control" placeholder="" required value={values.email}
                            name="email" onChange={handleChange} onBlur={handleBlur} />
                          {errors.email && touched.email ? <span style={{ fontWeight: 'bold', color: 'red', }}>{errors.email}</span> : null}
                        </div>
                        <div className="mb-4">
                          <div className="d-flex align-items-start">
                            <div className="flex-grow-1">
                              <label className="form-label">Password</label>
                            </div>
                            {/* <div className="flex-shrink-0">
                          <div className="">
                            <Link to="/merchant/forgot-password" className="text-muted">Forgot password?</Link>
                          </div>
                        </div> */}
                          </div>
                          <div className="mb-4">
                            <div className="mb-4">
                              <div className="ShowHidePass">
                                <input
                                  type={passType}
                                  className="form-control"
                                  required placeholder="Enter Password"
                                  value={values.Password}
                                  name="Password"
                                  onChange={handleChange}
                                  onBlur={handleBlur} />
                                <img onClick={showHideFun} className="eyeIco" src={`/imgs/${icoSrc}.svg`} />
                              </div>
                            </div>

                          </div>
                          {errors.Password && touched.Password ? <span style={{ fontWeight: 'bold', color: 'red', }}>{errors.Password}</span> : null}
                        </div>
                        <div className="row mb-4">
                          <div className="col">
                            <div className="form-check">
                              <input className="form-check-input" type="checkbox" id="remember-check" />
                              <label className="form-check-label" htmlFor="remember-check">
                                Remember me
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-check">
                              <Link to="/admin/otp" type="submit" className="text-primary text-decoration-underline">  Forget Password </Link>
                            </div>
                          </div>

                        </div>
                        <div className="mb-3">
                          {/* <Link to="/admin/dashboard" type="submit" className="btn bg-primary text-white w-100 waves-effect waves-light"> Log In </Link> */}
                          <button className="btn bg-primary text-white w-100 waves-effect waves-light" onClick={handleSubmit}

                          >Log In
                            <div
                              id="saveSpinner"
                              style={{
                                display: "none",
                                height: "15px",
                                width: "15px",
                                marginLeft: "5px",
                              }}
                              className="spinner-border"
                              role="status"
                            ></div>
                            <i
                              id="failedSaveErrorIcon"
                              style={{
                                display: "none",
                                marginLeft: "5px",
                              }}
                              className="fa fa-exclamation-triangle"
                              aria-hidden="true"
                            ></i>
                          </button>
                          {/* <Link to="/admin/dashboard" className="btn btn-primary w-100 waves-effect waves-light">Log In</Link> */}
                        </div>
                      </Form>
                      {/* <div className="mt-5 text-center">
                    <p className="text-muted mb-0">Don't have an account ? <Link to="/merchant/register"
                      className="text-primary fw-semibold"> Signup now </Link> </p>
                  </div> */}
                    </div>
                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0"> {new Date().getFullYear()} © R D Digital</p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <div className="col-xxl-9 col-lg-8 col-md-7">
              <div className="auth-bg pt-md-5 p-4 d-flex">
                <div className="bg-overlay bg-primary"></div>
                <ul className="bg-bubbles">
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                </ul>
                <div className="row justify-content-center align-items-center">
                  <div className="col-xl-7">
                    <div className="p-0 p-sm-4 px-xl-0">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </>
  );
}
export default Login;  