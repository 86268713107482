import { Link } from "react-router-dom";
import './style.css'
import Text from '../Text'
function CustomerService(props) {

    return (
        <>
            <div className='container mt-5'>
                <div className="row">
                    <div className="col-lg-4">
                        <div className={`customerService  ${props.customClass}`}>
                            <div className="customerService-card bg-gray-light  d-flex align-items-start">
                                <div className="customerService-card-img">
                                    <img src="/imgs/truck.svg" />
                                </div>
                                <div className="customerService-card-text ml-3">
                                    <Text
                                        text="Collect in store from London, Birmingham or East Grinstead"
                                        color="primary"
                                        variant="20"
                                        customClass="text-uppercase font-secondary text-weight-600"
                                    />
                                    <Text
                                        text="On Your Bike do not supply delivery services in respect of Goods sold to non-UK resident customers."
                                        variant="20"
                                        customClass="text-uppercase font-secondary text-weight-400 mt-2"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className={`customerService  ${props.customClass}`}>
                            <div className="customerService-card bg-gray-light  d-flex align-items-start">
                                <div className="customerService-card-img">
                                    <img src="/imgs/call.svg" />
                                </div>
                                <div className="customerService-card-text ml-3">
                                    <Text
                                        text="Customer Service available 
                                        7 days a week"
                                        color="primary"
                                        variant="20"
                                        customClass="text-uppercase font-secondary text-weight-600"
                                    />
                                    <Text
                                        text="We aim as always to offer you a great
                                        customer service experience."
                                        variant="20"
                                        customClass="text-uppercase font-secondary text-weight-400 mt-2"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className={`customerService-card bg-gray-light  d-flex align-items-start ${props.customClass}`}>
                            <div className="customerService-card-img">
                                <img src="/imgs/map.svg" />
                            </div>
                            <div className="customerService-card-text ml-3">
                                <Text
                                    text="Easy & Safe Online 
                                    Shopping"
                                    color="primary"
                                    variant="20"
                                    customClass="text-uppercase font-secondary text-weight-600"
                                />
                                <Text
                                    text="Easy and Safe online shopping at 
                                    On Your Bike."
                                    variant="20"
                                    customClass="text-uppercase font-secondary text-weight-400 mt-2"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default CustomerService