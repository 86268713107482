import React, { useEffect, useState, useRef } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import HeaderImg from "../components/HeaderImg";
import BreadCrumb from "../components/Breadcrumb";
import Text from '../components/Text'
import Button from '../components/Button'
import { config } from "../config";
import moment from "moment";
// import { API } from "../routesAPI";
import axios from "axios";
import { useFormik } from "formik";


function BlogDetail(props) {

    const initialValues = {
        message: "",
    };

    const locat = useLocation();
    const [blogName, setBlogName] = useState("");
    const [description, setDescription] = useState("");
    const [image, setImage] = useState("");
    const [dateBlog, setDateBlog] = useState("");
    const [reviewsList, setReviewsList] = useState([]);

    const { values, errors, handleBlur, touched, handleChange, handleSubmit, setFieldValue, } = useFormik({
        initialValues: initialValues,
        // validationSchema: addBrandSchema,
        onSubmit: (values) => {
            // addReview(values);
        },
    });

    useEffect(() => {
        window.scrollTo(0, 0)
        if (locat && locat.state && locat.state.id) {
            console.log(locat.state.id, "id")
            console.log(locat.state.description, "id")
            console.log(locat.state.name, "id")
            console.log(locat.state.date, "id")
            console.log(locat.state.image, "id")
            setBlogName(locat.state.name)
            setDescription(locat.state.description)
            setImage(locat.state.image)
            setDateBlog(locat.state.date)
        }
    }, [locat && locat.state.id])

    const getDate = (dateBlog) => {
        const date = moment(dateBlog).utc().format('YYYY-MM-DD')
        return date
    }



    return (
        <>
            <HeaderImg
                title="Blog Detail"
                imgSrc="/imgs/contact.jpg"
                align="center"
            >
                <BreadCrumb
                    menuActive="Blog Detail"
                    align="center"
                />
            </HeaderImg>
            <div className="blog-page py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 mb-5">
                            <div className="blog-heroImg">
                                <img src={image ? `${config.imgurl}${image}` : <></>} className="w-50" />
                                <div className='d-flex align-items-center mt-2  justify-content-between'>
                                    <div className="">
                                        <i class="fa fa-calendar-check-o " aria-hidden="true"></i>
                                        <span> 
                                            {getDate(dateBlog)}
                                            </span>
                                    </div>
                                    {/* <div className="text-primary">
                                        <a className="text-underline" href="#commentBox" >Write a comment...</a>
                                    </div> */}
                                </div>
                                <div className="divider my-2 bg-gray"></div>
                                <Text
                                    text={blogName}
                                    variant="22"
                                    customClass=" text-weight-800  mb-2"
                                />
                                <p className="text-title-18" dangerouslySetInnerHTML={{ __html: description }} >

                                </p>
                            </div>

                          
                            
                        </div>

                    </div>
                </div>


            </div>
        </>
    )
}

export default BlogDetail