import * as Yup from 'yup';

export const formSchema = Yup.object({
  firstName: Yup.string().min(2).max(25).matches(/^[A-Za-z]+$/, "only letters allowed").required("First name cannot be empty"),
  lastName: Yup.string().min(2).max(25).matches(/^[A-Za-z]+$/, "only letters allowed").required("Last name cannot be empty"),
  houseNumber: Yup.string().required("House no cannot be empty"),
  address: Yup.string().required("Address cannot be empty"),
  country: Yup.string().matches(/^[A-Za-z]+$/, "Only letters allowed").required("Country cannot be empty"),
  postalCode: Yup.string().required("Postalcode cannot be empty"),
  telePhone: Yup.string().min(10).max(10).required("TelePhone cannot be empty"),
  email: Yup.string().email().required("Email cannot be empty"),

})

export const loginSchema = Yup.object({
  email: Yup.string().email().required("Email cannot be empty"),
  Password: Yup.string().required("Password cannot be empty"),
})
export const forgotSchema = Yup.object({
  email: Yup.string().email().required("Email cannot be empty"),

})
export const resetSchema = Yup.object({
  verifyotp: Yup.number().required("OTP cannot be empty"),
  Password: Yup.string().required("Password cannot be empty"),
  ConfirmPassword: Yup.string().oneOf([Yup.ref('Password'), null], 'Passwords must match').required("Password cannot be empty"),
})


// ------------------------------------------------Admin_Schema------------------------------------------------
export const adminLoginSchema = Yup.object({
  email: Yup.string().email().required("Email cannot be empty"),
  Password: Yup.string().required("Password cannot be empty"),
})
export const createCategorySchema = Yup.object({
  type: Yup.string().required("Service Name cannot be empty"),
  description: Yup.string().required("Description cannot be empty"),
  // fullDescription: Yup.string().required("Description cannot be empty"),
  // image: Yup.string().required("Password cannot be empty"),
})