import { Link } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import { Navigation, Pagination, Autoplay, Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import StarsRating from "stars-rating";
import Button from "../components/Button";
import Text from "../components/Text";
import CustomerService from "../components/CustomerService";
import HeaderImg from "../components/HeaderImg";
import BreadCrumb from "../components/Breadcrumb";
import Newsletter from "../components/Newsletter";
import { useNavigate } from "react-router-dom";
import config from "../config";
import axios from "axios";
import moment from "moment";

function Testimonials() {
  const navigate = useNavigate();
  const [testimonials, setTestimonials] = useState([]);

  //+++++++++++++++++++Get Testimonials+++++++++++++++++++++++++++

  const listTestimonials = async () => {
    try {
      const response = await axios.get(
        `${config.config.siteURL}testimonial/getAll`
      );
      console.log(response.data.data, "testimonials");
      const List = response.data.data;
      // toast(response.data.data.message);
      setTestimonials(List);
    } catch (error) {
      // toast(error.response.data.response.message);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    listTestimonials();
  }, []);

  const getDate = (d) => {
    const date = moment(d).utc().format("YYYY-MM-DD");
    return date;
  };
  return (
    <div className="bikeFitting-divider">
      <HeaderImg title="TESTIMONIALS" imgSrc="/imgs/review.jpg" align="center">
        <BreadCrumb menuActive="Testimonials" align="center" />
      </HeaderImg>
      <section className=" mt-5 bike-fitting ">
        {testimonials &&
          testimonials.map((item, i) => {
            return (
              <div className="container ">
                <div className="row align-items-center">
                  <div className="col-xl-6 col-lg-7">
                    <div className="w-70">
                      <img className="w-60" src="/imgs/Siya.jpg" />
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-4">
                    <div className="bike-fitting-text bg-white py-5 pl-5 pr-2">
                      <StarsRating
                        count={5}
                        value={5}
                        edit={true}
                        size={24}
                        color2={"#ffd700"}
                      />
                      <Text
                        customClass="text-weight-600"
                        text={item.clientName}
                        variant="36"
                      />
                      <Text
                        customClass="text-weight-400"
                        text={item.service}
                        variant="26"
                      />
                      <Text
                        customClass="mt-2"
                        text={item.description.replace(/<\/?[^>]+(>|$)/g, "")}
                        variant="16"
                      />
                      {/* <Text
                                    customClass="mt-3"
                                    text="It is a long established fact that a reader will be distracted by the readable content of a 
                                    page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less
                                    normal distribution of letters, as opposed to using 'Content here, content here', making it 
                                    look like readable English. Many desktop publishing packages and web page editors now use 
                                    Lorem Ipsum as their default model text, and a search for "
                                    variant="16"
                                /> */}
                      <Button
                        customClass=""
                        text="Contact Us"
                        btnBg="primary mt-4"
                        btnOnClick={() => navigate("/contactus")}
                      />
                      {/* <Link to="/contact" className="bg-primary dp-btn mt-4">Contact Us</Link> */}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </section>

      {/* <section className=' mt-5 bike-fitting '>
                <div className='container '>
                    <div className="row text-center">
                        <div className="col-md-8 mx-auto">
                            <Text
                                customClass="text-weight-700"
                                text="Our aim"
                                variant="36"
                            />
                            <Text
                                customClass="mt-2 text-weight-500"
                                text="“When riding your bike you should feel powerful and efficient, with no pain or discomfort. Your pedalling should feel smooth and ‘easy’ and you will feel stable and 
                                planted on your saddle and pedals.”"
                                variant="16"
                            />
                            <Text
                                customClass="mt-3"
                                text="If the paragraph above does not describe your riding position then a Bike Fit may be for you. Find out below how we can help you achieve an awesome riding position!"
                                variant="16"
                            />
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-lg-4 mb-4">
                            <div 
                                className="bike-fitting-card h-100 p-4"
                                style={{background: "#EDEDED"}}
                            >
                                <Text 
                                    text="Bike Sizing Service - £25 (refunded on purchase of any bike)"
                                    variant="26"
                                    customClass="text-weight-600"
                                />
                                <div className="bike-fitting-divider bg-primary my-4"></div>
                                <Text 
                                    text="We offer a Sizing Service with all drop-handlebar 
                                    bikes that we sell. This ensures you purchase the 
                                    correct bike, in the correct size to suit you. For many 
                                    customers this is enough for them to be able to get 
                                    out and enjoy their cycling. We undertake this Sizing 
                                    Service using our state-of-the-art Retul Muve Fit Bike. 
                                    This allows us to mock up any size of bike in order you 
                                    to try out any bike before you buy."
                                    variant="16"
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 mb-4">
                            <div 
                                className="bike-fitting-card h-100 p-4"
                                style={{background: "#EDEDFF"}}
                            >
                                <Text 
                                    text={[<span>Body Position Fit &#40;Road Bikes&#41; - &pound;89</span>]}
                                    variant="26"
                                    customClass="text-weight-600"
                                />
                                <div className="bike-fitting-divider bg-primary my-4"></div>
                                <Text 
                                    text="A Bike Fit is not just for 'professionals' - in fact the 
                                    opposite may well be true! Recreational riders looking 
                                    to get more enjoyment/miles out of their riding are 
                                    ideal candidates for a Bike Fit.
                                    "
                                    variant="16"
                                />
                                <Text 
                                    customClass="mt-3"
                                    text="Body Positioning is a knowledge-based approach to 
                                    bike fitting rather than formula based. We look at cleat
                                    position, saddle height and fore/aft position and 
                                    handlebar position."
                                    variant="16"
                                />
                                <Text 
                                    customClass="mt-3"
                                    text="This fit option is typically completed on your own bike, 
                                    mounted on a static trainer. In some cases (for example 
                                    prior to a new bike purchase we will complete the fit on 
                                    our state-of-the-art Retul Muve Fit Bike)."
                                    variant="16"
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 mb-4">
                            <div 
                                className="bike-fitting-card h-100 p-4"
                                style={{background: "#EDF9FF"}}
                            >
                                <Text 
                                    text={[<span>Foot Optimisation <span className="d-none d-lg-inline"><br></br></span> Fit -  &pound;119</span>]}
                                    variant="26"
                                    customClass="text-weight-600"
                                />
                                <div className="bike-fitting-divider bg-primary my-4"></div>
                                <Text 
                                    text="For riders who have already had a Fit or are reasonably 
                                    happy with their position there may still be gains to be 
                                    had in terms of increasing stability, power and endurance 
                                    on the bike."
                                    variant="16"
                                    customClass=" text-weight-500"
                                />
                                <Text 
                                    text="The majority of people display asymmetries and 
                                    imperfections which can result in poor proprioception 
                                    from the feet, thus destabilizing the pelvis. In many 
                                    cases saddle discomfort and other aches and pains can 
                                    be attributed to poorly supported feet."
                                    variant="16"
                                    customClass="mt-3 text-weight-500"
                                />
                                <Text 
                                    text="This fit option will assess your feet, shoes and cleats in 
                                    order to effectively re-activate this proprioceptive 
                                    feedback. Please note here may well be further purchases
                                     required on top of the Fit fee – typically £20-£100 
                                    depending on the need for wedges and footbeds."
                                    variant="16"
                                    customClass="mt-3 text-weight-500"
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 mb-4">
                            <div 
                                className="bike-fitting-card h-100 p-4 "
                                style={{background: "#FFEDED"}}
                            >
                                <Text 
                                    text={[<span>Body Position <span className="d-none d-lg-inline"><br></br></span> Fit - &#40;Mountain Bikes&#41; &pound;119</span>]}
                                    variant="26"
                                    customClass="text-weight-600"
                                />
                                <div className="bike-fitting-divider bg-primary my-4"></div>
                                <Text 
                                    text="For riders who have already had a Fit or are reasonably 
                                    happy with their position there may still be gains to be 
                                    had in terms of increasing stability, power and endurance 
                                    on the bike."
                                    variant="16"
                                    customClass=""
                                />
                                <Text 
                                    text="The majority of people display asymmetries and 
                                    imperfections which can result in poor proprioception 
                                    from the feet, thus destabilizing the pelvis. In many 
                                    cases saddle discomfort and other aches and pains can 
                                    be attributed to poorly supported feet."
                                    variant="16"
                                    customClass="mt-3"
                                />
                                <Text 
                                    text="This fit option will assess your feet, shoes and cleats in 
                                    order to effectively re-activate this proprioceptive 
                                    feedback. Please note here may well be further purchases
                                     required on top of the Fit fee – typically £20-£100 
                                    depending on the need for wedges and footbeds."
                                    variant="16"
                                    customClass="mt-3"
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 mb-4">
                            <div 
                                className="bike-fitting-card h-100 p-4 "
                                style={{background: "#EDF6ED"}}
                            >
                                <Text 
                                    text={[<span>Body Position Fit <span className="d-none d-lg-inline"><br></br></span> &#40;Time Trial/Triathlon&#41; &pound;50/hour</span>]}
                                    variant="26"
                                    customClass="text-weight-600"
                                />
                                <div className="bike-fitting-divider bg-primary my-4"></div>
                                <Text 
                                    text="For riders who have already had a Fit or are reasonably 
                                    happy with their position there may still be gains to be 
                                    had in terms of increasing stability, power and endurance 
                                    on the bike."
                                    variant="16"
                                    customClass=""
                                />
                                <Text 
                                    text="The majority of people display asymmetries and 
                                    imperfections which can result in poor proprioception 
                                    from the feet, thus destabilizing the pelvis. In many 
                                    cases saddle discomfort and other aches and pains can 
                                    be attributed to poorly supported feet."
                                    variant="16"
                                    customClass="mt-3"
                                />
                                <Text 
                                    text="This fit option will assess your feet, shoes and cleats in 
                                    order to effectively re-activate this proprioceptive 
                                    feedback. Please note here may well be further purchases
                                     required on top of the Fit fee – typically £20-£100 
                                    depending on the need for wedges and footbeds."
                                    variant="16"
                                    customClass="mt-3"
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 mb-4">
                            <div 
                                className="bike-fitting-card h-100 p-4 "
                                style={{background: "#EDF6ED"}}
                            >
                                <Text 
                                    text={[<span>Cleat Fit - &pound;15</span>]}
                                    variant="26"
                                    customClass="text-weight-600"
                                />
                                <div className="bike-fitting-divider bg-primary my-4"></div>
                                <Text 
                                    text="For riders who have already had a Fit or are reasonably 
                                    happy with their position there may still be gains to be 
                                    had in terms of increasing stability, power and endurance 
                                    on the bike."
                                    variant="16"
                                    customClass=""
                                />
                                <Text 
                                    text="The majority of people display asymmetries and 
                                    imperfections which can result in poor proprioception 
                                    from the feet, thus destabilizing the pelvis. In many 
                                    cases saddle discomfort and other aches and pains can 
                                    be attributed to poorly supported feet."
                                    variant="16"
                                    customClass="mt-3"
                                />
                                <Text 
                                    text="This fit option will assess your feet, shoes and cleats in 
                                    order to effectively re-activate this proprioceptive 
                                    feedback. Please note here may well be further purchases
                                     required on top of the Fit fee – typically £20-£100 
                                    depending on the need for wedges and footbeds."
                                    variant="16"
                                    customClass="mt-3"
                                />
                            </div>
                        </div>
                    </div>
                   
                </div>
            </section> */}

      {/* <section className='additional-purchase mt-5 text-center d-flex align-items-center'>
                <div className='container '>
                    <div className="row ">
                        <div className="col-xl-12">
                            <div className="p-2">
                                <Text
                                    customClass="text-weight-700"
                                    text="Additional purchases"
                                    variant="36"
                                    color="white"
                                />
                                <div className="text-title-16 mt-2 text-white">
                                    Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, 
                                    a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of 
                                    the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and
                                    Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes 
                                    from a line in section 1.10.32.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

      {/* <section className='mt-5 d-flex align-items-center'>
                <div className='container '>
                    <div className="row align-items-center">
                        <div className="col-lg-4">
                            <div className="">
                                <Text
                                    customClass="text-weight-700 text-uppercase"
                                    text="Testimonials"
                                    variant="36"
                                />
                                <Text
                                    text="Contrary to popular belief,  Lorem Ipsum is not simpl"
                                    variant="36"
                                />
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="bg-pink px-4 py-3 ">
                                <Swiper
                                    modules={[Navigation, Pagination, Scrollbar]}
                                    navigation
                                    spaceBetween={0}
                                    slidesPerView={1}
                                    onSwiper={(swiper) => console.log(swiper)}
                                    onSlideChange={() => console.log('slide change')}
                                    autoplay={true}
                                    className="bikeFitting-review arrowPrimary"
                                >
                                    <SwiperSlide className="sliderr">
                                        <StarsRating
                                            count={5}
                                            value={3.5}
                                            edit={true}
                                            size={24}
                                            color1={"#000"}
                                            color2={"#CE9400"}
                                        />
                                        <div className="text-title-16 mt-2 d-flex align-items-center text-weight-700 w-100">
                                            <span>John, </span>
                                            <span>after Velo Birmingham,</span>
                                            <span>25/9/2017:</span>
                                        </div>
                                        <Text 
                                            customClass="mt-2"
                                            text={[<span>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from
                                                    45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked
                                                    up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of 
                                                    the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 
                                                    1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and  Evil) by Cicero, written in 45 BC. </span>]} 
                                        />
                                        <div className="text-title-16 mt-5 d-flex align-items-center justify-content-end text-weight-700 w-100">
                                            <span>Date, </span>
                                            <span>25/9/2017:</span>
                                        </div>
                                    </SwiperSlide>  
                                    <SwiperSlide className="sliderr">
                                        <StarsRating
                                            count={5}
                                            value={3.5}
                                            edit={true}
                                            size={24}
                                            color2={"#ffd700"}
                                        />
                                        <div className="text-title-16 mt-2 d-flex align-items-center text-weight-700 w-100">
                                            <span>John, </span>
                                            <span>after Velo Birmingham,</span>
                                            <span>25/9/2017:</span>
                                        </div>
                                        <Text 
                                            customClass="mt-2"
                                            text={[<span>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from
                                                    45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked
                                                    up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of 
                                                    the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 
                                                    1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and  Evil) by Cicero, written in 45 BC. </span>]} 
                                        />
                                        <div className="text-title-16 mt-5 d-flex align-items-center justify-content-end text-weight-700 w-100">
                                            <span>Date, </span>
                                            <span>25/9/2017:</span>
                                        </div>
                                    </SwiperSlide>  
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

      {/* <CustomerService customClass="mt-4"/> */}

      {/* ======= Book A Call ======== */}

      <div className="container py-4 mt-4">
        <div style={{ borderTop: "1px solid #ddd" }}></div>
        <div className="row ">
          <div className={` col-md-6 text-center mx-auto my-4`}>
            <Newsletter />
          </div>
        </div>
      </div>

      {/* ======= NewsLetter ======== */}
    </div>
  );
}

export default Testimonials;
