import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Sidebar from "./Sidebar";
import NavBar from "./NavBar";
import Style from "./AdminStyle";
import { useFormik } from 'formik';
import $ from "jquery";
import upload from "../../assets/images/uploadIcon.svg";
import config from "../../config";
import axios from "axios";

window.jQuery = window.$ = $;
require("jquery-nice-select");

function EditAbout() {
  const selectRef1 = useRef();
  const selectRef2 = useRef();
  const selectRef3 = useRef();
  const location = useLocation();
  const navigate = useNavigate();

  const [noOfRows, setNoOfRows] = useState(1);

  const initialValues = {
    title: "",
    description1: "",
    description2: "",
    image: "",
  };

  const {
    values,
    errors,
    handleBlur,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {
      editAbout(values);
    },
  });

  const editAbout = async (value) => {
    let data = value;
    try {
      const response = await axios.patch(`${config.config.siteURL}about/editAbout/${location.state.id}`, data);
    //    toast(response.data.data.message)
      navigate("/admin/AboutList");
    } catch (error) {
    //   toast(error.response.data.data.message);
    }
  };

  const getAboutById = async (id) => {
    try {
      const response = await axios.get(
        `${config.config.siteURL}about/get_about_by_id?id=${id}`
      );
      const { title,description1,description2,image } = response.data.data;
      setFieldValue("title", title);
      setFieldValue("description1", description1);
      setFieldValue("description2", description2);
      setFieldValue("image", image);
    } catch (error) {}
  };


const setImage = async (e) => {
  const formData = new FormData();
  const file = e.target.files[0];
  formData.append("image", file);
  let image = "";
  for (let key of formData.entries()) {
    console.log(key[0] + " ,  " + key[1]);
    image = key[1];
  }
  if (file) {
    try {
      const response = await axios.post(
        `${config.config.siteURL}s3upload/image-upload`,
        formData
      );
      setFieldValue("image", response.data.data.response);
      console.log(values);
    } catch (error) {
      console.log(error);
    }
  }
};

  useEffect(() => {
    $(selectRef1.current).niceSelect();
  }, []);

  useEffect(() => {
    $(selectRef2.current).niceSelect();
  }, []);

  useEffect(() => {
    $(selectRef3.current).niceSelect();
  }, []);

  useEffect(() => {
    location.state &&
      location.state.id &&
      getAboutById(location.state.id);
  }, [location.state && location.state.id]);

  return (
    <>
      <Style />
      <article id="root" className="mainRoot">
        <div className="wrapper">
          <div className="sidebar">
            <Sidebar />
          </div>
          <div className="content-page">
            <div className="content">
              <div className="MainNavRow">
                <NavBar />
              </div>
              <div className="container-fluid  mt-4">
                <div className="cardFull  Bgwhite Shadow radius20 p-4 mx-4">
                  <div className="addPrdctRow">
                    <div className="MainHdng">
                      <h3>Add about </h3>
                    </div>
                    <div class="hrStyle pb-5">
                      <hr />
                    </div>
                    <div className="addAcordion">
                      <form class="formStyle addFormStyle" onSubmit={handleSubmit}>
                        <div class="row row d-flex align-items-center ">
                          <div className="col-sm-2 text-end">
                            <Form.Label>Page Title</Form.Label>
                          </div>
                          <div class="col">
                            <Form.Control
                              type="text"
                              placeholder="Enter Title"
                              name="title"
                              value={values.title}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div class=" mb-15">
                          <div class="row row d-flex align-items-center mt-4">
                            <div className="col-sm-2 text-end">
                              <label class="form-label" for="input5">
                                Description 1{" "}
                                <span class="contact__form--label__star">
                                  *
                                </span>
                              </label>
                            </div>
                            <div class="col">
                              <div className="ckEditor">
                                <CKEditor
                                  editor={ClassicEditor}
                                  data={values.description1}
                                  onReady={(editor) => {
                                    // You can store the "editor" and use when it is needed.
                                    console.log(
                                      "Editor is ready to use!",
                                      editor
                                    );
                                  }}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setFieldValue("description1", data);
                                  }}
                                  onBlur={(event, editor) => {}}
                                  onFocus={(event, editor) => {}}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class=" mb-15">
                          <div class="row row d-flex align-items-center mt-4">
                            <div className="col-sm-2 text-end">
                              <label class="form-label" for="input5">
                                Description 2
                                <span class="contact__form--label__star">
                                  *
                                </span>
                              </label>
                            </div>
                            <div class="col">
                              <div className="ckEditor">
                                <CKEditor
                                  editor={ClassicEditor}
                                  data={values.description2}
                                  onReady={(editor) => {
                                    // You can store the "editor" and use when it is needed.
                                    console.log(
                                      "Editor is ready to use!",
                                      editor
                                    );
                                  }}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setFieldValue("description2", data);
                                  }}
                                  onBlur={(event, editor) => {
                                    console.log("Blur.", editor);
                                  }}
                                  onFocus={(event, editor) => {
                                    console.log("Focus.", editor);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="mb-15 mt-4">
                          <div className="row">
                            <div className="col-sm-2 text-end">
                              <Form.Label>Add Image</Form.Label>
                            </div>
                            <div className="col">
                              <div className="form-group">
                                <div class="uplogInrDiv">
                                  <input
                                    type="file"
                                    className="form-control fileUpload  form-control-lg"
                                    onChange={(e) => setImage(e)}
                                  />
                                  <div class="uploadBlkInr">
                                    <div class="uplogImg">
                                      <img
                                        src={
                                          values.image
                                            ? `${config.config.imgurl}${values.image}`
                                            : upload
                                        }
                                        alt=""
                                        height="50"
                                      />
                                    </div>
                                    <div class="uploadFileCnt">
                                      <p>Drag an image here or browse</p>
                                      <p>for an image to upload</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="SeoForm">
                          <div className="row d-flex align-items-center my-3 mb-4">
                            <div className="col-sm-2 text-end">
                              <Form.Label>Meta Tag Title </Form.Label>
                            </div>
                            <div class="col">
                              <input
                                type="text"
                                name="product_description[1][meta_title]"
                                placeholder="Meta Tag Title"
                                id="input-meta-title1"
                                class="form-control"
                              />
                            </div>
                          </div>
                          <div className="row d-flex align-items-center my-3  mb-4">
                            <div className="col-sm-2 text-end">
                              <Form.Label>Meta Tag Description </Form.Label>
                            </div>
                            <div class="col">
                              <textarea className="form-control"></textarea>
                            </div>
                          </div>
                          <div className="row d-flex align-items-center my-3 ">
                            <div className="col-sm-2 text-end">
                              <Form.Label>Meta Tag Keywords </Form.Label>
                            </div>
                            <div class="col">
                              <textarea className="form-control"></textarea>
                            </div>
                          </div>
                        </div>
                        <div className="row d-flex align-items-center my-3  mb-4">
                          <div className="col-sm-2 text-end"></div>
                          <div class="col">
                            <button class="btnCommon" type="submit" btnOnClick={(e)=>{
                                e.preventDefault();
                                handleSubmit()
                            }}>
                              Continue
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </>
  );
}

export default EditAbout;
