import React, { useEffect, useState, useRef } from "react";
import { Link, NavLink,useNavigate } from "react-router-dom";
import {
  Button,
  Table,
  Tab,
  Tabs,
  Row,
  Col,
  Alert,
  Container,
  Form,
  label,
} from "react-bootstrap";
import Sidebar from "./Sidebar";
import NavBar from "./NavBar";
import $ from "jquery";
import Style from "./AdminStyle";
import moment from "moment";
import view from "../../assets/images/icons/view-icon.png";
import viewWhite from "../../assets/images/icons/view-white-icon.png";
import deleteIcon from "../../assets/images/icons/delete-icon.png";
import deleteWhite from "../../assets/images/icons/delete-white-icon.png";
import editIcon from "../../assets/images/icons/edit-icon.png";
import editWhite from "../../assets/images/icons/edit-white-icon.png";
import product1 from "../../assets/images/productImg1.jpg";
import config from "../../config";
import axios from "axios";

window.jQuery = window.$ = $;
require("jquery-nice-select");
function TestimonialsList(props) {
  const selectRef1 = useRef();
  useEffect(() => {
    $(selectRef1.current).niceSelect();
  }, []);
  const selectRef2 = useRef();
  useEffect(() => {
    $(selectRef2.current).niceSelect();
  }, []);
  const selectRef3 = useRef();
  useEffect(() => {
    $(selectRef3.current).niceSelect();
  }, []);
  const [Data, setData] = useState([]);

  const navigate = useNavigate()

  // -----------------------------Get Testimonials---------------------------------

  const listTestimonials = async () => {
    try {
      const response = await axios.get(
        `${config.config.siteURL}testimonial/getAll`
      );
      console.log(response.data.data, "contacttt");
      const List = response.data.data;
      // toast(response.data.data.message);
      setData(List);
    } catch (error) {
      // toast(error.response.data.response.message);
    }
  };

  const deleteTestimonial = async (id) => {
    try {
        const res = await axios.delete(
          `${config.config.siteURL}testimonial/deleteTestimonial/${id}`
        );
        listTestimonials();
      } catch (error) {}
  }
 
  
  useEffect(() => {
    listTestimonials();
  }, []);
  const getDate = (d) => {
    const date = moment(d).utc().format("YYYY-MM-DD");
    return date;
  };

  return (
    <>
      <Style />
      <article id="root">
        <div className="wrapper">
          <div className="sidebar">
            <Sidebar />
          </div>
          <div className="content-page">
            <div className="content">
              <div className="MainNavRow">
                <NavBar />
              </div>
              <div className="container-fluid">
                <div className="cardFull p-4">
                  <div className="row align-items-center justify-content-center pb-20 hdngRowBlk">
                    <div className="col">
                      <div className="MainHdng">
                        <h4>Testimonials List</h4>
                      </div>
                    </div>
                    <div className="col-auto">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search Order"
                      />
                    </div>
                    <div className="col-auto">
                      <div className="prdctsortCol">
                        <div className="form-group">
                          <select ref={selectRef3} className="wide">
                            <option value="Featured">Orders</option>
                            <option value="10"> All Orders</option>
                            <option value="25">Summary</option>
                            <option value="50">Completed</option>
                            <option value="100">Cancelled</option>
                            <option value="100"> Refund</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-auto">
                      <div className="prdctsortCol">
                        <div className="form-group">
                          <select ref={selectRef2} className="wide">
                            <option value="Featured">Sort By</option>
                            <option value="10">Asc</option>
                            <option value="25">Desc</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="hrStyle pb-5">
                    <hr />
                  </div>
                  <div className="prdctListTble common-table">
                    <Table classname="table">
                      <thead>
                        <tr>
                          <th>S No.</th>
                          <th>Name </th>
                          <th>Image</th>
                          <th>Date</th>
                          <th>service</th>
                          <th>comment</th>

                          <th align="center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Data &&
                          Data.map((item, i) => {
                            return (
                              <tr className="whitebgRow">
                                <td>{i++ + 1}</td>
                                <td className="price">{item.clientName}</td>
                                <td>
                                  <div className="prdctImg">
                                    {/* <img src={product1} alt="" height="80" /> */}
                                    <img
                                      src={
                                        item.image
                                          ? `${config.config.imgurl}${item.image}`
                                          : product1
                                      }
                                      alt=""
                                      height="80"
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className="SubCat">
                                    {" "}
                                    {getDate(item.creationDate)}
                                  </div>
                                </td>
                                <td>
                                  <div className="green">{item.service}</div>
                                </td>

                                <td className="status">
                                  <Link to="/" className="btnCommon btnDark">
                                    {item.description}
                                  </Link>
                                </td>

                                <td className="actions">
                                  <div class="tbl-actn">
                                    <ul>
                                      {/* <li class="view-btn">
                                                            <div class="">
                                                                <a href="/admin/vieworder">
                                                                    <img src={view} alt="" height="18" />
                                                                    <span><img src={viewWhite} alt="" height="18" /></span>
                                                                </a>
                                                            </div>
                                                        </li> */}
                                      <li class="edit-btn">
                                        <div class=""  onClick={() => {
                                            navigate(
                                              "/admin/EditTestimonials",
                                              {
                                                state: {
                                                  id: item._id,
                                                },
                                              }
                                            );
                                          }}>
                                          <Link
                                          >
                                            <img
                                              src={editIcon}
                                              alt=""
                                              height="18"
                                            />
                                            <span>
                                              <img
                                                src={editWhite}
                                                alt=""
                                                height="18"
                                              />
                                            </span>
                                          </Link>
                                        </div>
                                      </li>
                                      <li class="delete-btn">
                                        <div class="" onClick={()=>deleteTestimonial(item._id)}>
                                          <a>
                                            <img
                                              src={deleteIcon}
                                              alt=""
                                              height="18"
                                            />
                                            <span>
                                              <img
                                                src={deleteWhite}
                                                alt=""
                                                height="18"
                                              />
                                            </span>
                                          </a>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </>
  );
}
export default TestimonialsList;
