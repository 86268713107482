
import './style.css'

function HeaderImg(props) {

    return (
        <>
           
            <div className={`Header-img position-relative overlay ${props.customClass}`}> 
                 {/* <img src={props.imgSrc} /> */} 
                 <div className='Header-imgSrc' style={{background: `url(${props.imgSrc}) no-repeat top center/cover`}}></div>
                <div className={`position-absolute Header-img-text text-center ${props?.align  ? (props.align) : ""} `} >
                    <h1 className='text-title-46 text-white'>
                        <span>{props.title}</span>
                    </h1>
                    <div className={`Header-img-child`}>
                        {props.children}
                    </div>
                </div>
            </div>
              
        </>
    )
}

export default HeaderImg