import React, { useEffect, useState, useRef } from "react";
import { Link, NavLink, useLocation, useParams } from "react-router-dom";
import HeaderImg from "../components/HeaderImg";
import BreadCrumb from "../components/Breadcrumb";
import Text from "../components/Text";
import Button from "../components/Button";
import { useNavigate } from "react-router-dom";
import { config } from "../config";
// import moment from "moment";
// import { API } from "../routesAPI";
import axios from "axios";
import { useFormik } from "formik";

function ServiceDetail(props) {
  const navigate = useNavigate();
  const { slug } = useParams();
  const [service, setService] = useState();

  useEffect(() => {
    slug && getService(slug);
  }, [slug]);

  const getService = async (slug) => {
    const response = await axios.get(
      `${config.siteURL}service/serviceSlug/${slug}`
    );
    setService(response.data.data);
  };

  return (
    <>
      <HeaderImg
        title="Service Detail"
        imgSrc="/imgs/contact.jpg"
        align="center"
      >
        <BreadCrumb menuActive="Service Detail" align="center" />
      </HeaderImg>
      <section className="my-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-4"></div>
            <div className="col-lg-4">
              <img className="w-100" src="/imgs/service-bike.png" />
            </div>
            <div className="col-lg-4"></div>
            <div className="col-lg-12">
              <Text
                customClass="text-weight-600 mt-4 text-center"
                text={service?.type}
                variant="26"
              />
             {<Text
                customClass=" text-center"
                text={service?.description.replace(
                  /<\/?[^>]+(>|$)/g,
                  ""
                )}
                variant="16"
              />}
            </div>
            <section className="text-center mt-5 ">
              <Button
                text="Contact Us"
                btnBg="primary"
                btnOnClick={() => navigate("/contactus")}
              />
            </section>
          </div>
          {/* <div className='row align-items-center'>
                        <div className='col-lg-7'>
                            <Text
                                customClass="text-weight-600 mt-3"
                                text="Cycle workshop services"
                                variant="26"
                            />
                            <Text
                                customClass=""
                                text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been 
                                the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of 
                                type and scrambled it to make a type specimen book. It has survived not only five centuries, but also 
                                the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960
                                with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with 
                                desktop publishing software  like Aldus PageMaker including versions of Lorem Ipsum."
                                variant="16"
                            />
                            <Text
                                customClass="mt-3"
                                text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been 
                                the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of 
                                type and scrambled it to make a type specimen book. It has survived not only five centuries, but also 
                                the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960
                                with the release of."
                                variant="16"
                            />
                             <Button
                                customClass=""
                                text="Contact Us"
                                btnBg="primary mt-3"
                            />
                        </div>
                        <div className='col-lg-5'>
                            <img className='w-100' src='/imgs/service-cycle.png' />
                        </div>
                    </div> */}
        </div>
      </section>

      {/* <section className='my-5'>
                <div className='container'>
                    <div className='row align-items-center text-center'>
                        <div className='col-lg-12'>
                            <Text
                                customClass="text-weight-600"
                                text="We are approved service centres for the following:"
                                variant="26"
                            />
                            <Text
                                customClass="text-weight-600"
                                text="Brompton B-spoke Centre"
                                variant="26"
                            />
                            <Text
                                customClass=""
                                text="This is the highest accreditation offered to stores by Brompton. The workshop carries an extensive range of spares, as well as Brompton specific
                                tools. This allows servicing to all frame joints, Sturmey Archer and Sram hubs, in addition to general repairs"
                                variant="16"
                            />
                        </div>
                        <div className='col-lg-12 mt-3'>
                            <img src='/imgs/approve.jpg' />
                        </div>
                    </div>
                </div>
            </section> */}
    </>
  );
}

export default ServiceDetail;
