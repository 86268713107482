import React from "react";
import { Link } from 'react-router-dom';

import  './style.css'

import Text from '../Text'



function Footer(props) {
  
  return (
    <footer className={`dp-footer ${props.customClass}`}>
            <div className='container-fluid '>
                <div className='row pb-5'>
                    <div className='col-md-3'>
                        <Link to="/" >
                            <img src='/logo.svg' />
                        </Link>
                        {/* <Text
                            customClass="footer-descrip pr-5 mt-3"
                            variant="description"
                            text="On Your Bike is a family owned and run business that prides itself on great customer service. First opening its doors in 1983 was our flagship London store, shortly followed by Birmingham, and most recently East Grinstead, in 2016."
                        /> */}
                    </div>
                    <div className='col-md-7'>
                        <div className='row'>
                            <div className='col-md-3'>
                                <div className="footer-list-title font-secondary text-white">
                                    <span>About Us</span>
                                </div>
                                {/* <ul>
                                    <li>
                                        <Link className=" text-underline-none" to="">London</Link>
                                    </li>
                                    <li>
                                        <Link className=" text-underline-none" to="">Birmingham</Link>
                                    </li>
                                    <li>
                                        <Link className=" text-underline-none" to="">East Grinstead</Link>
                                    </li>
                                    <li>
                                        <Link className=" text-underline-none" to="">Opening Hours</Link>
                                    </li>
                                    <li>
                                        <Link className=" text-underline-none" to="">Find us</Link>
                                    </li>
                                    <li>
                                        <Link className=" text-underline-none" to="">Jobs</Link>
                                    </li>
                                    <li>
                                        <Link className=" text-underline-none" to="">Blogs</Link>
                                    </li>
                                </ul> */}
                            </div>
                            <div className='col-md-3'>
                                <div className="footer-list-title font-secondary text-white">
                                    <span>Testimonials</span>
                                </div>
                                {/* <ul>
                                    <li>

                                        <Link className=" text-underline-none" to="/">Home</Link>

                                    </li>
                                    <li>

                                        <Link className=" text-underline-none" to="">Bikes </Link>

                                    </li>
                                    <li>

                                        <Link className=" text-underline-none" to="">Products</Link>

                                    </li>
                                    <li>

                                        <Link className=" text-underline-none" to="#">Bike Rental</Link>

                                    </li>
                                    <li>

                                        <Link className=" text-underline-none" to="">Repair/Servicing</Link>

                                    </li>
                                    <li>
                                        <Link className=" text-underline-none" to="">Cycle To Work</Link>
                                    </li>
                                    <li>
                                        <Link className=" text-underline-none" to="/contact">Contact</Link>
                                    </li>
                                </ul> */}
                            </div>
                            <div className='col-md-3'>
                                <div className="footer-list-title font-secondary text-white">
                                    <span>Services</span>
                                </div>
                                {/* <ul>
                                    <li>
                                        <Link className=" text-underline-none" to="/"> Youtube Marketing and Optimization</Link>
                                    </li>
                                    <li>
                                        <Link className=" text-underline-none" to="">Lead Generation </Link>
                                    </li>
                                    <li>
                                        <Link className=" text-underline-none" to="">Email Marketing</Link>
                                    </li>
                                    <li>
                                        <Link className=" text-underline-none" to="">Paid Marketing</Link>
                                    </li>
                                    <li>
                                        <Link className=" text-underline-none" to="">SMO</Link>
                                    </li>
                                    <li>
                                        <Link className=" text-underline-none" to="">SEO</Link>
                                    </li>
                                   
                                </ul> */}
                            </div>
                            <div className='col-md-3'>
                                <div className="footer-list-title font-secondary text-white">
                                    <span>Contact us</span>
                                </div>
                                {/* <ul>
                                    <li>
                                        <Link className=" text-underline-none" to="/"> My Account</Link>
                                    </li>
                                    <li>
                                        <Link className=" text-underline-none" to="">Delivery Info</Link>
                                    </li>
                                    <li>
                                        <Link className=" text-underline-none" to="">Bike Fitting</Link>
                                    </li>
                                    <li>
                                        <Link className=" text-underline-none" to="">Returns</Link>
                                    </li>
                                    <li>
                                        <Link className=" text-underline-none" to="">FAQ's</Link>
                                    </li>
                                    <li>
                                        <Link className=" text-underline-none" to="">Cycle To Work</Link>
                                    </li>
                                    <li>
                                        <Link className=" text-underline-none" to="">T's &amp; C's</Link>
                                    </li>
                                    <li>
                                        <Link className=" text-underline-none" to="">Policy</Link>
                                    </li>
                                </ul> */}
                            </div>

                        </div>
                    </div>
                    <div className='col-md-2 downloadApp'>
                        <div className="footer-list-title font-secondary text-white">
                            <span>Follow Us </span>
                        </div>
                        <div className="mt-3 ">
                            <div className="footer-socialMedia mt-3 mb-3">
                                {/* <Link className='mr-2' to=""><img src='/imgs/fb.svg' alt=""/></Link>
                                <Link className='mr-2' to=""><img src='/imgs/insta.svg' alt=""/></Link>
                                <Link className='mr-2' to=""><img src='/imgs/tw.svg' alt=""/></Link>
                                <Link className='' to="www.linkedin.com"><img src='/imgs/in.svg' alt="www.linkedin.com"/></Link> */}
                            </div>
                            {/* <div className="footer-list-title font-secondary text-white">
                                <span>Follow Us </span>
                            </div>
                            <div className="download-media mt-3">
                                <Link to="#"><img src="/imgs/app-store.png" alt="" /></Link>                            
                                <Link to="#"><img src="/imgs/google-store.png" alt="" /></Link>
                            </div> */}
                        </div>
                    </div>
                </div>

                <div className="text-center footer-copyright d-flex align-items-center justify-content-center">
                    <div className="mr-3">
                        <span>© RD Digital</span>
                        {/* <span>© RD Digital| VAT 669 9389 48 | Terms</span> */}
                        {/* <span >PAYMENT METHODS</span> */}
                    </div>
                     {/* <img src="/imgs/visa.png" /> */}
                </div>
            </div>
        </footer>
  );
}

// {window.onscroll = function() {myFunction()};

//   function myFunction() {
//     if (document.body.scrollTop > 300 || document.documentElement.scrollTop > 300) {
//       document.getElementById("dupleHeader").classList.add("dp-header-fixed");
//     } else {
//       document.getElementById("dupleHeader").classList.remove("dp-header-fixed");
//     }
//   }}

export default Footer;


