import { useState, useEffect } from 'react'
import { Link,navigate, useNavigate} from "react-router-dom";
import Button from "../components/Button";

import { forgotSchema } from '../components/Schemas'
import { useFormik } from 'formik';
import BreadCrumb from '../components/Breadcrumb'
import Text from '../components/Text'
import Newsletter from '../components/Newsletter'
import Input from '../components/Input'
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios"
import { config } from '../config';

function ChangePassword() {

    const navigate = useNavigate()

    const initialValues = {
        email: ""
    }

    const [passType, setPassType] = useState("password");
    const [icoSrc, setIcoSrc] = useState("eyeHide");
    // const [loader, setloader] = useState("false");

    const forgotPassword = async (value) => {
        localStorage.setItem('email',value.email );
        let data = value
        try {
            const response = await axios.post(`${config.siteURL}user/forgetPassword/`, data);
            console.log(response , "gggg")
            if(response){
                toast(response.data.msg)
                navigate("/change-password")
                // setloader(true)
            }
        } catch (error) {
            // toast(error.response.data.data.message)
        }
    }

    const showHideFun = () => {
        if (passType === "password" && icoSrc === "eyeHide") {
            setPassType("text")
            setIcoSrc("eyeShow")
        } else {
            setPassType("password")
            setIcoSrc("eyeHide")
        }
    }
    const [passType2, setPassType2] = useState("password");
    const [icoSrc2, setIcoSrc2] = useState("eyeHide");

    const showHideFun2 = () => {
        if (passType2 === "password" && icoSrc === "eyeHide") {
            setPassType2("text")
            setIcoSrc2("eyeShow")
        } else {
            setPassType2("password")
            setIcoSrc2("eyeHide")
        }
    }
    const { values, errors, handleBlur, touched, handleChange, handleSubmit } = useFormik({
        initialValues: initialValues,
        validationSchema: forgotSchema,
        onSubmit: (values) => {
            console.log("fsdf")
            forgotPassword(values)
        }
    })

    return (
        <>
        <ToastContainer />
            <BreadCrumb
                customClass="mr-5 my-2"
                menuActive="Contact"
                align="end"
                color="black"
            />
            <section className='changePassword-form py-5'>
                <div className='container'>
                    <div className="row">
                        <div className="col-lg-6 mx-auto">
                            <div className="bg-primary p-5 radius-5">
                                <Text
                                    customClass="text-weight-600 d-inline text-uppercase"
                                    text="OTP VERIFY"
                                    variant="36"
                                    color="white"
                                />
                                <div className='headingDivider bg-white mb-4 '></div>
                                <form className="row common-form common-form-borderless common-form-bg">
                                    <div className="col-lg-12 mb-4 pr-0">
                                        <label className='text-title-16' for="email">Enter your email address below and we'll send you One Time
                                            Password (OTP) to your mobile number.</label>
                                        <input className="box-shadow mt-2" id="otp" type="email" placeholder="Enter Email" value={values.email}
                                            name="email" onChange={handleChange} onBlur={handleBlur} />
                                        {errors.email && touched.email ? <span style={{ fontWeight: 'bold', color: 'blue', }}>{errors.email}</span> : null}
                                    </div>


                                    <div className="col-md-12 col-12 ml-auto mt-4">
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <Link to="" className='bg-none text-white text-underline-none text-title-18'><i class="fa fa-long-arrow-left" aria-hidden="true"></i>  &nbsp; Back</Link>
                                            <button type="submit" onClick={handleSubmit} className='text-weight-600 bg-white text-black dp-btn'>Request OTP</button>

                                            {/* <Button
                                            customClass=" text-weight-600"
                                            text="Request OTP"
                                            btnBg="white"
                                            color="black"
                                        /> */}
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            {/* ======= NewsLetter ======== */}

            <div className="container py-4 mt-4">
                <div style={{ borderTop: "1px solid #ddd" }}></div>
                <div className="row ">
                    <div className={` col-md-6 text-center mx-auto my-4`}>
                        <Newsletter />
                    </div>
                </div>
            </div>

            {/* ======= NewsLetter ======== */}

        </>
    );
}

export default ChangePassword