import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Sidebar from "./Sidebar";
import NavBar from "./NavBar";
import Style from "./AdminStyle";
import { useFormik } from "formik";
import $ from "jquery";
import upload from "../../assets/images/uploadIcon.svg";
import config from "../../config";
import axios from "axios";
import UploadImages from "../Upload";

window.jQuery = window.$ = $;
require("jquery-nice-select");

function AddAbout() {
  const selectRef1 = useRef();
  const selectRef2 = useRef();
  const selectRef3 = useRef();
  const navigate = useNavigate();

  const initialValues = {
    title: "",
    description1: "",
    description2: "",
    image: [],
  };

  const {
    values,
    errors,
    handleBlur,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {
      createAbout(values);
    },
  });

  const createAbout = async (values) => {
    const formData = new FormData();

    // Append other key-value pairs from the 'values' object to the FormData
    Object.entries(values).forEach(([key, val]) => {
      // If it's an array of files, append each file
      if (Array.isArray(val)) {
        val.forEach((file, index) => {
          formData.append("files", file, file.name);
        });
      } else {
        formData.append(key, val);
      }
    });
    try {
      const response = await axios.post(
        `${config.config.siteURL}about/`,
        formData
      );
      //    toast(response.data.data.message)
      navigate("/admin/AboutList");
    } catch (error) {
      //   toast(error.response.data.data.message);
    }
  };

  const setImages = (file) => {
    setFieldValue("image", file);
  };

  useEffect(() => {
    $(selectRef1.current).niceSelect();
  }, []);

  useEffect(() => {
    $(selectRef2.current).niceSelect();
  }, []);

  useEffect(() => {
    $(selectRef3.current).niceSelect();
  }, []);

  return (
    <>
      <Style />
      <article id="root" className="mainRoot">
        <div className="wrapper">
          <div className="sidebar">
            <Sidebar />
          </div>
          <div className="content-page">
            <div className="content">
              <div className="MainNavRow">
                <NavBar />
              </div>
              <div className="container-fluid  mt-4">
                <div className="cardFull  Bgwhite Shadow radius20 p-4 mx-4">
                  <div className="addPrdctRow">
                    <div className="MainHdng">
                      <h3>Add about </h3>
                    </div>
                    <div class="hrStyle pb-5">
                      <hr />
                    </div>
                    <div className="addAcordion">
                      <form
                        class="formStyle addFormStyle"
                        onSubmit={handleSubmit}
                      >
                        <div class="row row d-flex align-items-center ">
                          <div className="col-sm-2 text-end">
                            <Form.Label>Page Title</Form.Label>
                          </div>
                          <div class="col">
                            <Form.Control
                              type="text"
                              placeholder="Enter Title"
                              name="title"
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div class=" mb-15">
                          <div class="row row d-flex align-items-center mt-4">
                            <div className="col-sm-2 text-end">
                              <label class="form-label" for="input5">
                                Description 1{" "}
                                <span class="contact__form--label__star">
                                  *
                                </span>
                              </label>
                            </div>
                            <div class="col">
                              <div className="ckEditor">
                                <CKEditor
                                  editor={ClassicEditor}
                                  data={values.description1}
                                  onReady={(editor) => {
                                    // You can store the "editor" and use when it is needed.
                                    console.log(
                                      "Editor is ready to use!",
                                      editor
                                    );
                                  }}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setFieldValue("description1", data);
                                  }}
                                  onBlur={(event, editor) => {}}
                                  onFocus={(event, editor) => {}}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class=" mb-15">
                          <div class="row row d-flex align-items-center mt-4">
                            <div className="col-sm-2 text-end">
                              <label class="form-label" for="input5">
                                Description 2
                                <span class="contact__form--label__star">
                                  *
                                </span>
                              </label>
                            </div>
                            <div class="col">
                              <div className="ckEditor">
                                <CKEditor
                                  editor={ClassicEditor}
                                  data={values.description2}
                                  onReady={(editor) => {
                                    // You can store the "editor" and use when it is needed.
                                    console.log(
                                      "Editor is ready to use!",
                                      editor
                                    );
                                  }}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setFieldValue("description2", data);
                                  }}
                                  onBlur={(event, editor) => {
                                    console.log("Blur.", editor);
                                  }}
                                  onFocus={(event, editor) => {
                                    console.log("Focus.", editor);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="mb-15 mt-4">
                          <UploadImages
                            image={values?.image}
                            setImages={setImages}
                          />
                        </div>
                        <div className="SeoForm">
                          <div className="row d-flex align-items-center my-3 mb-4">
                            <div className="col-sm-2 text-end">
                              <Form.Label>Meta Tag Title </Form.Label>
                            </div>
                            <div class="col">
                              <input
                                type="text"
                                name="product_description[1][meta_title]"
                                placeholder="Meta Tag Title"
                                id="input-meta-title1"
                                class="form-control"
                              />
                            </div>
                          </div>
                          <div className="row d-flex align-items-center my-3  mb-4">
                            <div className="col-sm-2 text-end">
                              <Form.Label>Meta Tag Description </Form.Label>
                            </div>
                            <div class="col">
                              <textarea className="form-control"></textarea>
                            </div>
                          </div>
                          <div className="row d-flex align-items-center my-3 ">
                            <div className="col-sm-2 text-end">
                              <Form.Label>Meta Tag Keywords </Form.Label>
                            </div>
                            <div class="col">
                              <textarea className="form-control"></textarea>
                            </div>
                          </div>
                        </div>
                        <div className="row d-flex align-items-center my-3  mb-4">
                          <div className="col-sm-2 text-end"></div>
                          <div class="col">
                            <button
                              class="btnCommon"
                              type="submit"
                              btnOnClick={(e) => {
                                e.preventDefault();
                                handleSubmit();
                              }}
                            >
                              Continue
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </>
  );
}
export default AddAbout;
