import React from "react";
import './style.css'

function Button(props) {
   
  return (    
    <button 
      className={`dp-btn dp-border-none bg-${props.btnBg} text-${props.color} ${props.customClass}`}
      id={props.id}
      disabled={props.disabled}
      style={{fontSize: `${props.fontSize}px`, lineHeight: `${props.lineHeight}px`}} 
      onClick={(e)=>props.btnOnClick(e)}
    >    
      <span className={`leftContent ${props.leftContentClass}`}>{props.leftContent}</span>  <span>{props.text}</span> <span className={`rightContent ${props.rightContentClass}`}>{props.rightContent}</span>
      
    </button>
  );
}

export default Button;


//  btnBg ---  for give a background to button
//  color --- is for button color

