import React from "react";
// import PropTypes from 'prop-types'

import './style.css'

function Text (props) {
   
  return (
    <div id={props.id} style={props.css} 
    className={`text-title text-title-${props.variant} text-${props.color} ${props.customClass} ${props.align?`text-${props.align}`:""}`}>
        <span className="title-caption" style={{fontSize: `${props.fontSize}px` , lineHeight: `${props.lineHeight}px`}}>{props.text}</span>
        {props?.children ?
          <span className={`${props.childClass}`}>{props.children}</span>
          : ""
        }
    </div>
  );
}

// Text.propTypes = {
//   after: PropTypes.bool,
// };

export default Text;
