import React, { useEffect, useState, useRef } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Table,
  Tab,
  Tabs,
  Row,
  Col,
  Alert,
  Container,
  Form,
  label,
  Accordion,
} from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Style from "./AdminStyle";
import Sidebar from "./Sidebar";
import NavBar from "./NavBar";
import $ from "jquery";
import upload from "../../assets/images/uploadIcon.svg";
import noimage from "../../assets/images/noImage.jpg";
import plus from "../../assets/images/plus.svg";
import axios from "axios";
import config from "../../config";
import { useFormik } from "formik";
import UploadImages from "../Upload";

window.jQuery = window.$ = $;
require("jquery-nice-select");
function AddBlog() {
  const navigate = useNavigate();
  const selectRef1 = useRef();
  useEffect(() => {
    $(selectRef1.current).niceSelect();
  }, []);
  const selectRef2 = useRef();
  useEffect(() => {
    $(selectRef2.current).niceSelect();
  }, []);
  const selectRef3 = useRef();
  useEffect(() => {
    $(selectRef3.current).niceSelect();
  }, []);
  const [noOfRows, setNoOfRows] = useState(1);

  const { state } = useLocation();

  const initialValues = {
    type: "",
    description: "",
    image: [],
    cat_id: "",
    blogCategories: [],
  };

  const {
    values,
    errors,
    handleBlur,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {
      editCategory();
    },
  });

  const editCategory = async () => {
    let data = {};
    data["type"] = values.type;
    data["description"] = values.description;
    data["image"] = values.image;
    data["cat_id"] = values.cat_id;

    const formData = new FormData();

    // Append other key-value pairs from the 'values' object to the FormData
    Object.entries(data).forEach(([key, val]) => {
      // If it's an array of files, append each file
      if (Array.isArray(val)) {
        val.forEach((file, index) => {
          formData.append("files", file, file.name);
        });
      } else {
        formData.append(key, val);
      }
    });

    try {
      const response = await axios.patch(
        `${config.config.siteURL}blog/editBlog/${state.id}`,
        formData
      );
      //  toast(response.data.data.message)
      navigate("/admin/BlogList");
    } catch (error) {
      // toast(error.response.data.data.message)
    }
  };

  const setImages = (file) => {
    setFieldValue("image", file);
  };

  const getBlogList = async () => {
    try {
      const res = await axios.get(
        `${config.config.siteURL}blog/get_blog_by_id?id=${state.id}`
      );
      setFieldValue("type", res.data.data.type);
      setFieldValue("description", res.data.data.description);
      setFieldValue("image", res.data.data.image);
      setFieldValue("cat_id", res.data.data.cat_id);
    } catch (error) {}
  };

  const getBlogCategory = async () => {
    try {
      const response = await axios.get(
        `${config.config.siteURL}blog/getAllCategoryBlogs`
      );
      setFieldValue("blogCategories", response.data.response);
    } catch (error) {}
  };

  useEffect(() => {
    getBlogCategory();
    getBlogList();
  }, []);

  return (
    <>
      <Style />

      <article id="root" className="mainRoot">
        <div className="wrapper">
          <div className="sidebar">
            <Sidebar />
          </div>
          <div className="content-page">
            <div className="content">
              <div className="MainNavRow">
                <NavBar />
              </div>
              <div className="container-fluid  mt-4">
                <div className="cardFull  Bgwhite Shadow radius20 p-4 mx-4">
                  <div className="addPrdctRow">
                    <div className="MainHdng mb-3">
                      <h3>Add Blog</h3>
                    </div>
                    <div className="addAcordion">
                      <form
                        class="formStyle addFormStyle"
                        onSubmit={handleSubmit}
                      >
                        <Accordion defaultActiveKey="0">
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>Add Blog Info</Accordion.Header>
                            <Accordion.Body>
                              <div class="row">
                                <div className="col">
                                  <div class=" mb-3">
                                    <div className="row">
                                      <div className="col">
                                        <Form.Label>Blog Title</Form.Label>
                                        <Form.Control
                                          type="text"
                                          placeholder="Enter Blog Name"
                                          value={values.type}
                                          name="type"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                        />
                                      </div>
                                      <div className="col">
                                        <Form.Label>
                                          Choose Category{" "}
                                          <span class="contact_star">*</span>
                                        </Form.Label>
                                        <div className="form-group">
                                          {values.blogCategories?.length >
                                            0 && (
                                            <select
                                              //   ref={selectRef1}
                                              className="wide"
                                              name="cat_id"
                                              value={values.cat_id}
                                              onChange={handleChange}
                                            >
                                              <option value="">
                                                Select Category
                                              </option>
                                              {values.blogCategories.map(
                                                (data) => {
                                                  return (
                                                    <option value={data._id}>
                                                      {data.type}
                                                    </option>
                                                  );
                                                }
                                              )}
                                            </select>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div class=" mb-15">
                                    <label class="form-label" for="input5">
                                      Blog Description{" "}
                                      <span class="contact__form--label__star">
                                        *
                                      </span>
                                    </label>
                                    <div className="ckEditor">
                                      <CKEditor
                                        editor={ClassicEditor}
                                        data={values.description}
                                        onReady={(editor) => {
                                          // You can store the "editor" and use when it is needed.
                                          console.log(
                                            "Editor is ready to use!",
                                            editor
                                          );
                                        }}
                                        onChange={(event, editor) => {
                                          const data = editor.getData();
                                          setFieldValue("description", data);
                                          console.log(values, "taa");
                                        }}
                                        onBlur={(event, editor) => {
                                          console.log("Blur.", editor);
                                        }}
                                        onFocus={(event, editor) => {
                                          console.log("Focus.", editor);
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="2">
                            <Accordion.Header>Upload Images</Accordion.Header>
                            <Accordion.Body>
                              <div className="col">
                                <UploadImages
                                  image={values?.image}
                                  setImages={setImages}
                                />
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="3">
                            <Accordion.Header>SEO</Accordion.Header>
                            <Accordion.Body>
                              <div className="SeoForm">
                                <div className="row d-flex align-items-center my-3 mb-4">
                                  <div className="col-sm-2 text-end">
                                    <Form.Label>Meta Tag Title </Form.Label>
                                  </div>
                                  <div class="col">
                                    <input
                                      type="text"
                                      name="product_description[1][meta_title]"
                                      placeholder="Meta Tag Title"
                                      id="input-meta-title1"
                                      class="form-control"
                                    />
                                  </div>
                                </div>
                                <div className="row d-flex align-items-center my-3  mb-4">
                                  <div className="col-sm-2 text-end">
                                    <Form.Label>
                                      Meta Tag Description{" "}
                                    </Form.Label>
                                  </div>
                                  <div class="col">
                                    <textarea className="form-control"></textarea>
                                  </div>
                                </div>
                                <div className="row d-flex align-items-center my-3 ">
                                  <div className="col-sm-2 text-end">
                                    <Form.Label>Meta Tag Keywords </Form.Label>
                                  </div>
                                  <div class="col">
                                    <textarea className="form-control"></textarea>
                                  </div>
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                        <div className="">
                          <button
                            class="btnCommon  mt-3 "
                            type="submit"
                            onSubmit={(e) => {
                              e.preventDefault();
                              handleSubmit();
                            }}
                          >
                            Continue
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </>
  );
}
export default AddBlog;
