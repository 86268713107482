import { Link } from "react-router-dom";
import "./style.css";

function BreadCrumb(props) {
  return (
    <>
      <div className={`breadcrumb ${props.customClass}`}>
        <ul
          className={`d-flex align-items-center list-group m-0 p-0 justify-content-${props.align}`}
        >
          <li className={`text-${props.color}`}>
            <Link className="text-underline-none dp-color-secondary" to="/">
              Home<span className="breadcrumbArrow">&gt;&gt;</span>
            </Link>
          </li>
          {props?.menu1 && props.menuLink1 ? (
            <li className={`text-${props.color}`}>
              <Link
                className="text-underline-none dp-color-secondary"
                to={props.menuLink1}
              >
                {props.menu1}
                <span className="breadcrumbArrow">&gt;&gt;</span>
              </Link>
            </li>
          ) : (
            ""
          )}
          {props?.menu2 && props.menuLink2 ? (
            <li className={`text-${props.color}`}>
              <Link
                className="text-underline-none dp-color-secondary"
                to={props.menuLink2}
              >
                {props.menu2}
                <span className="breadcrumbArrow">&gt;&gt;</span>
              </Link>{" "}
            </li>
          ) : (
            ""
          )}
          <li className={`active text-${props.color}`}>
            <span>{props.menuActive}</span>
          </li>
        </ul>
      </div>
    </>
  );
}

export default BreadCrumb;
