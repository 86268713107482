import { Link } from "react-router-dom";
import {useState} from 'react' 
import { Row, Col, Alert, Container, Form ,label} from "react-bootstrap"
import logo from "../../assets/images/LogoLg.svg";
import Style from './AdminStyle';
function ForgotPassword() {
  const [passType, setPassType] = useState("password");
    const [icoSrc, setIcoSrc] = useState("eyeHide");
    const showHideFun = () =>{
      if (passType === "password" && icoSrc=== "eyeHide"){
          setPassType("text")
          setIcoSrc("eyeShow")
      }else{
          setPassType("password")
          setIcoSrc("eyeHide")
      }
  } 

  const [passType2, setPassType2] = useState("password");
  const [icoSrc2, setIcoSrc2] = useState("eyeHide");

  const showHideFun2 = () =>{
      if (passType2 === "password" && icoSrc=== "eyeHide"){
          setPassType2("text")
          setIcoSrc2("eyeShow")
      }else{
          setPassType2("password")
          setIcoSrc2("eyeHide")
      }
  } 


     return (
      <>
        <Style/>
    <div className="auth-page">
    <Container fluid className="p-0">
      <Row className="g-0">
        <Col lg={4} md={5} className="col-xxl-3">
          <div className="auth-full-page-content d-flex p-sm-5 p-4">
            <div className="w-100">
              <div className="d-flex flex-column h-100">
                <div className="mb-4 mb-md-5 text-center">
                  <Link to="/admin/login" className="d-block auth-logo">
                    <img src={logo} alt="" height="53" />
                  </Link>
                </div>
                <div className="auth-content my-auto">
                  <div className="text-center">
                    <h5 className="mb-0">Change Password</h5>
                    <p className="text-muted mt-2">Change Password with R D Digital</p>
                  </div>
                  <Form className="formStyle mt-4 pt-2">                  
                    <div className="row  mt-3">
                        <div className="col-lg-12 mb-3">
                            <Form.Label>Verify OTP</Form.Label>
                            <Form.Control type="text" placeholder="Verify OTP" /> 
                        </div>                                                     
                        <div className="col-lg-12 mb-3">
                              <Form.Label>Create Password</Form.Label>
                            <div className="ShowHidePass">
                              <img onClick={showHideFun} className="eyeIco" src={`/imgs/${icoSrc}.svg`} />
                              <Form.Control  type={passType} placeholder="Create Password" /> 
                            </div> 
                        </div>                                                     
                        <div className="col-lg-12">
                            <Form.Label>Confirm Password</Form.Label>
                            <div className="ShowHidePass">
                              <img onClick={showHideFun2} className="eyeIco" src={`/imgs/${icoSrc}.svg`} />
                              <Form.Control type={passType2} placeholder="Confirm Password" /> 
                            </div>
                        </div>                                                     
                    </div>  
                  </Form>
                  
                  <div className="mb-3">
                        <Link to="" class="btnCommon mt-3  w-100 waves-effect waves-light" type="submit">Change Password</Link>
                    </div>
                </div>
                <div className="mt-4 mt-md-5 text-center">
                  <p className="mb-0"> {new Date().getFullYear()} © R D Digital</p>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <div className="col-xxl-9 col-lg-8 col-md-7">
                <div className="auth-bg pt-md-5 p-4 d-flex">
                    <div className="bg-overlay bg-primary"></div>
                    <ul className="bg-bubbles">
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </ul>
                    <div className="row justify-content-center align-items-center">
                        <div className="col-xl-7">
                            <div className="p-0 p-sm-4 px-xl-0">                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      </Row>
    </Container>
  </div> 
  </> 
  );
}
export default ForgotPassword;  
