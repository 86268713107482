import React, { useEffect, useState, useRef } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Table,
  Tab,
  Tabs,
  Row,
  Col,
  Alert,
  Container,
  Form,
  label,
  Accordion,
} from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Style from "./AdminStyle";
import Sidebar from "./Sidebar";
import NavBar from "./NavBar";
import $ from "jquery";
import upload from "../../assets/images/uploadIcon.svg";
import noimage from "../../assets/images/noImage.jpg";
import plus from "../../assets/images/plus.svg";
import axios from "axios";
import config from "../../config";
import { useFormik } from "formik";
import UploadImages from "../Upload";

window.jQuery = window.$ = $;
require("jquery-nice-select");
function EditServices() {
  const navigate = useNavigate();
  const selectRef1 = useRef();
  useEffect(() => {
    $(selectRef1.current).niceSelect();
  }, []);
  const selectRef2 = useRef();
  useEffect(() => {
    $(selectRef2.current).niceSelect();
  }, []);
  const selectRef3 = useRef();
  useEffect(() => {
    $(selectRef3.current).niceSelect();
  }, []);

  const [noOfRows, setNoOfRows] = useState(1);
  const { state } = useLocation();

  const initialValues = {
    type: "",
    description: "",
    image: [],
  };

  const {
    values,
    errors,
    handleBlur,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {
      editService(values);
    },
  });

  const editService = async (value) => {

    const formData = new FormData();

    // Append other key-value pairs from the 'values' object to the FormData
    Object.entries(value).forEach(([key, val]) => {
      // If it's an array of files, append each file
      if (Array.isArray(val)) {
        val.forEach((file, index) => {
          formData.append("files", file, file.name);
        });
      } else {
        formData.append(key, val);
      }
    });

    try {
      const response = await axios.patch(
        `${config.config.siteURL}service/editService/${state.id}`,
        formData
      );
      //  toast(response.data.data.message)
      navigate("/admin/ServicesList");
    } catch (error) {
      // toast(error.response.data.data.message)
    }
  };

  const getServiceList = async () => {
    try {
      const res = await axios.get(
        `${config.config.siteURL}service/get_service_by_id?id=${state.id}`
      );
      setFieldValue("type", res.data.data.type);
      setFieldValue("description", res.data.data.description);
      setFieldValue("image", res.data.data.image);
    } catch (error) {}
  };

  const setImages = (file) => {
    setFieldValue("image", file);
  };

  useEffect(() => {
    getServiceList();
  }, []);

  console.log(values);

  return (
    <>
      <Style />

      <article id="root" className="mainRoot">
        <div className="wrapper">
          <div className="sidebar">
            <Sidebar />
          </div>
          <div className="content-page">
            <div className="content">
              <div className="MainNavRow">
                <NavBar />
              </div>
              <div className="container-fluid  mt-4">
                <div className="cardFull  Bgwhite Shadow radius20 p-4 mx-4">
                  <div className="addPrdctRow">
                    <div className="MainHdng">
                      <h3>Edit Service</h3>
                    </div>
                    <div class="hrStyle pb-5">
                      <hr />
                    </div>
                    <div className="addAcordion">
                      <form
                        class="formStyle addFormStyle"
                        onSubmit={() => handleSubmit()}
                      >
                        <div class="row row d-flex align-items-center ">
                          <div className="col-sm-2 text-end">
                            <Form.Label>Edit Service Name </Form.Label>
                          </div>
                          <div class="col">
                            <Form.Control
                              type="text"
                              placeholder="Enter Service Name"
                              value={values.type}
                              name="type"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                        </div>
                        <div class=" mb-15">
                          <div class="row row d-flex align-items-center mt-4">
                            <div className="col-sm-2 text-end">
                              <label class="form-label" for="input5">
                                Description{" "}
                                <span class="contact__form--label__star">
                                  *
                                </span>
                              </label>
                            </div>
                            <div class="col">
                              <div className="ckEditor">
                                <CKEditor
                                  editor={ClassicEditor}
                                  data={values.description}
                                  onReady={(editor) => {
                                    // You can store the "editor" and use when it is needed.
                                    console.log(
                                      "Editor is ready to use!",
                                      editor
                                    );
                                  }}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setFieldValue("description", data);
                                    console.log(values, "taa");
                                  }}
                                  onBlur={(event, editor) => {
                                    console.log("Blur.", editor);
                                  }}
                                  onFocus={(event, editor) => {
                                    console.log("Focus.", editor);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mb-15 mt-4">
                          {/* <div className="row">
                            <div className="col-sm-2 text-end">
                              <Form.Label>Add Image</Form.Label>
                            </div>
                            <div className="col">
                              <div className="form-group">
                                <div class="uplogInrDiv">
                                  <input
                                    type="file"
                                    className="form-control fileUpload  form-control-lg"
                                    onChange={(e) => setImage(e)}
                                  />
                                  <div class="uploadBlkInr">
                                    <div class="uplogImg">
                                      <img
                                        src={
                                          values.image
                                            ? `${config.config.imgurl}${values.image}`
                                            : upload
                                        }
                                        alt=""
                                        height="50"
                                      />
                                    </div>
                                    <div class="uploadFileCnt">
                                      <p>Drag an image here or browse</p>
                                      <p>for an image to upload</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> */}
                          <UploadImages
                            image={values?.image}
                            setImages={setImages}
                          />
                        </div>
                        <div className="SeoForm">
                          <div className="row d-flex align-items-center my-3 mb-4">
                            <div className="col-sm-2 text-end">
                              <Form.Label>Meta Tag Title </Form.Label>
                            </div>
                            <div class="col">
                              <input
                                type="text"
                                name="product_description[1][meta_title]"
                                placeholder="Meta Tag Title"
                                id="input-meta-title1"
                                class="form-control"
                              />
                            </div>
                          </div>
                          <div className="row d-flex align-items-center my-3  mb-4">
                            <div className="col-sm-2 text-end">
                              <Form.Label>Meta Tag Description </Form.Label>
                            </div>
                            <div class="col">
                              <textarea className="form-control"></textarea>
                            </div>
                          </div>
                          <div className="row d-flex align-items-center my-3 ">
                            <div className="col-sm-2 text-end">
                              <Form.Label>Meta Tag Keywords </Form.Label>
                            </div>
                            <div class="col">
                              <textarea className="form-control"></textarea>
                            </div>
                          </div>
                        </div>
                        <div className="row d-flex align-items-center my-3  mb-4">
                          <div className="col-sm-2 text-end"></div>
                          <div class="col">
                            <button
                              class="btnCommon"
                              type="submit"
                              onClick={(e) => {
                                e.preventDefault();
                                handleSubmit();
                              }}
                            >
                              Continue
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </>
  );
}
export default EditServices;
