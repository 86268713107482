import React, { useEffect, useState,useRef } from "react";
import { Link, NavLink,} from "react-router-dom";
import { Button,Table, Tab, Tabs,Row, Col, Alert, Container, Form ,label } from "react-bootstrap";
import AdSidebar from './Sidebar';
import AdNavBar from './NavBar';
import $ from "jquery";
import Style from './AdminStyle';
import deleteIcon from "../../assets/images/icons/delete-icon.png";
import deleteWhite from "../../assets/images/icons/delete-white-icon.png";
import editIcon from "../../assets/images/icons/edit-icon.png";
import editWhite from "../../assets/images/icons/edit-white-icon.png";
import userImg from "../../assets/images/user1.jpg";
import config from "../../config";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';

window.jQuery = window.$ = $;
require("jquery-nice-select");
function CallList() {
    const selectRef1 = useRef();
    useEffect(() => {
  $(selectRef1.current).niceSelect();
    }, []);
    const selectRef2 = useRef();
    useEffect(() => {
  $(selectRef2.current).niceSelect();
    }, []);
    const selectRef3 = useRef();
    useEffect(() => {
  $(selectRef3.current).niceSelect();
    }, []);

    const [Data, setData] = useState([]);

    const listBookedCalls = async () => {
        try {
            const response = await axios.get(`${config.config.siteURL}appointment/getAll`);
            console.log(response.data.data, "?????????????????????")
            const List = response.data.data
            toast(response.data.data.message);
            setData(List)
            // setTotal(response.data.TotalCount)
        } catch (error) {
            toast(error.response.data.data.message);
        }
    };

    // const onDeleteCall = async (id) => {
    //     try {
    //         const res = await axios.delete(`${config.config.siteURL}service/deleteService/${id}`);
    //         listBookedCalls()
    //     } catch (error) {
    //     }
    // }

    useEffect(() => {
        listBookedCalls()
    }, []);
    
     return (
        <>
        <Style/>
         <article id="root">
            <div className="wrapper">
                 <div className="sidebar">
                 <AdSidebar/>
                 </div>
                <div className="content-page">
                    <div className="content">
                        <div className="MainNavRow">
                        <AdNavBar/>
                        </div>
                        <div className="container-fluid ">
                            <div className="cardFull p-4">
                                <div className="row align-items-center justify-content-center pb-20 hdngRowBlk">
                                    <div className="col">
                                        <div className="MainHdng">
                                            <h4>User List</h4>
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <input type="text" className="form-control" placeholder="User Search.."/>
                                    </div>
                                    <div className="col-auto">
                                        <div className="prdctsortCol">
                                            <div className="form-group">
                                                <select ref={selectRef3} className="wide">
                                                <option value="Featured">Search By</option>
                                                <option value="10">Name</option>
                                                <option value="25">Email</option>    
                                                <option value="50">Status</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <div className="prdctsortCol">
                                            <div className="form-group">
                                                <select ref={selectRef2} className="wide">
                                                <option value="Featured">Sort</option>
                                                <option value="10">10</option>
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>                              
                                <div className="hrStyle pb-5" ><hr/></div>
                            <div className="prdctListTble common-table">
                                <Table classname="table">
                                    <thead>
                                        <tr>
                                        <th>S No.</th>
                                        <th>Date</th>
                                        {/* <th>Image</th> */}
                                        <th>Time</th>                                       
                                        <th>Email</th>
                                        <th >Phone</th>
                                        <th >Country</th>
                                        {/* <th>Status</th> */}
                                        <th align="center" width="150">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                                    Data?.length > 0 && Data.map((data, index) => {
                                                        return (
                                        <tr className="whitebgRow">
                                            <td>
                                               { index++ + 1}
                                            </td>
                                            <td>{data.date}</td>
                                            {/* <td className="usrImgIcon">
                                            <span><img src={userImg} alt="" height="50" className="rounded-circle" /></span>
                                            </td> */}
                                            <td>
                                                <div className="prodctTitle">
                                                    <div className="d-flex">
                                                    <div>{data.time}</div>
                                                    </div>                                                    
                                                </div>
                                            </td>
                                            <td>
                                                <div className="">
                                                   {data.emailAddress}
                                                </div>
                                            </td>
                                            <td className="">
                                                <div className="pdrctCat">
                                             {data.phoneNumber}
                                                </div>
                                            </td>
                                            <td >
                                                <div className="">
                                                {data.country}
                                                </div>
                                            </td>
                                            {/* <td className="status">
                                                <Link to="/" className="btnCommon btnDark">In Active</Link>
                                            </td> */}
                                            <td className="actions">
                                                <div class="tbl-actn">
                                                    <ul>
                                                        <li class="edit-btn">
                                                            <div class="">
                                                                <a href="/admin/useredit">
                                                                    <img src={editIcon} alt="" height="18" />
                                                                    <span><img src={editWhite} alt="" height="18" /></span>                                                                 
                                                                </a>                                                                
                                                            </div>
                                                        </li>
                                                        <li class="delete-btn">
                                                            <div class="">
                                                                <a 
                                                                // onClick={() => onDeleteCall(data._id)}
                                                                >
                                                                    <img src={deleteIcon} alt="" height="18" />                                                                    
                                                                    <span>
                                                                    <img src={deleteWhite} alt="" height="18" />
                                                                    </span>                                                                     
                                                                </a>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                         )
                                        })
                                    }
                                     
                                    </tbody>
                                </Table>
                            </div>
                           
                        </div>
                        </div>
                </div>
            </div>
            </div>
         </article>   
         </>   
    
  );
}
export default CallList;  
