import { Link } from "react-router-dom";
import React, { useEffect, useState,useRef } from "react";
import notification from "../../assets/images/notification.svg";
import UserIcon from "../../assets/images/userIcon.png"; 
import navBarIcon from "../../assets/images/barIcon.svg"; 
import SetingUser from "../../assets/images/settingIcons/userIcon.svg"; 
import Edit from "../../assets/images/settingIcons/editIcon.svg"; 
import ChangePassword from "../../assets/images/settingIcons/changePaswrd.svg";
import SetingLogout from "../../assets/images/settingIcons/logout.svg"; 
import { Button, Modal, Dropdown, DropdownButton, Offcanvas, Accordion } from "react-bootstrap";
import $ from "jquery";
function AdNavBar() { 

  const adminName = localStorage.getItem("fullName")
  console.log(adminName,"admin")

  const handleClick = event => {
     event.currentTarget.classList.toggle('close');
  };
  const showonclick = event => {
    $("#root").toggleClass("intro");
  };
  const logout = () => {
   localStorage.clear()
  };
  const [show, setShow] = React.useState();
  return (
    <div className="navbar-custom align-items-center row  d-flex justify-content-between">
      <div className="row d-flex align-items-center">
        <div className="col">
          <div className="navBarClose">
          <a href="javascript:void(0);" onClick={() => showonclick()}>
            <img src={navBarIcon} alt="" height="18" className="" />
          </a>          
          </div>   
        </div> 
        <div className="col-auto">
          <div className="navRightSideBar d-flex align-items-center">
            <div className="notification-list">
            <Dropdown>
            <Dropdown.Toggle  className="notificatnCol" id="dropdown-basic">
             <span><img src={notification} alt="" height="22" /></span>
             <i className="notificationCryle"></i>
            </Dropdown.Toggle>
            {/* <Dropdown.Menu>
              <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
              <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
              <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
            </Dropdown.Menu> */}
          </Dropdown>
            </div>
            <div className="acntUsrBlk">
            <Dropdown>
            <Dropdown.Toggle  className="notificatnCol" id="dropdown-basic">
              <div className="userContnt d-flex">
                  <div className="usrImg"><span><img src={UserIcon} alt="" height="40" className="rounded-circle" /></span></div>
                  <div><p>Welcome</p>
                  <h5>{adminName}</h5>
                  </div>             
               </div> 
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item href="/admin/myprofile"><span><img src={SetingUser} alt="" height="13" /></span> View Profile</Dropdown.Item>
              <Dropdown.Item href="/admin/editprofile"><span><img src={Edit} alt="" height="13" /></span> Edit Profile</Dropdown.Item>
              <Dropdown.Item href="/admin/changepassword"><span><img src={ChangePassword} alt="" height="13" /></span> Change Password</Dropdown.Item>
              <Dropdown.Item href="/admin/Login" onClick={()=>logout()}><span><img src={SetingLogout} alt="" height="13"/></span> Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AdNavBar;