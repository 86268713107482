import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/grid";
import "swiper/css/navigation";
import { useNavigate } from "react-router-dom";
import StarsRating from "stars-rating";
import Text from "../components/Text";
import Card from "../components/ImgCard";
import BlogCard from "../components/BlogCard";
import Button from "../components/Button";
import Newsletter from "../components/Newsletter";
import config from "../config";
import axios from "axios";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import parse from "html-react-parser";

function Home() {
  const navigate = useNavigate();
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    service: "",
    comment: "",
  };

  const { values, errors, handleBlur, touched, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      // validationSchema: createContactSchema,
      onSubmit: (values) => {
        createContact(values);
      },
    });
  const [service, setService] = useState([]);
  const [testimonials, setTestimonials] = useState([]);
  const [description1, setDescription1] = useState("");

  // ++++++++++++++++++++++++service list ++++++++++++++++++++++++++++++++++
  const listService = async () => {
    try {
      const response = await axios.get(
        `${config.config.siteURL}service/getAllServices`
      );
      const List = response.data.data;
      setService(List);
    } catch (error) {
      // toast(error.response.data.data.message);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    listService();
    listTestimonials();
    listAbout();
  }, []);

  // ++++++++++++++++++++++++enquiry+++++++++++++++++++++++++++++++

  const createContact = async (value) => {
    try {
      const response = await axios.post(
        `${config.config.siteURL}contact/`,
        value
      );
      toast(response.data.data.message);
      window.location.reload();
    } catch (error) {
      toast(error.response.data.data.message);
    }
  };

  //+++++++++++++++++++Get Testimonials+++++++++++++++++++++++++++

  const listTestimonials = async () => {
    try {
      const response = await axios.get(
        `${config.config.siteURL}testimonial/getAll`
      );
      const List = response.data.data;
      // toast(response.data.data.message);
      setTestimonials(List);
    } catch (error) {
      // toast(error.response.data.response.message);
    }
  };

  const getDate = (d) => {
    const date = moment(d).utc().format("YYYY-MM-DD");
    return date;
  };

  // -----------------------------Get About's---------------------------------

  const listAbout = async () => {
    try {
      const response = await axios.get(`${config.config.siteURL}about/getAll`);
      const List = response.data.data;
      const description1 = response.data.data[0].description1.replace(
        /<\/?[^>]+(>|$)/g,
        ""
      );
      setDescription1(description1);
    } catch (error) {
      // handle error
    }
  };

  return (
    <>
      <div className="dp-slider">
        <img src="/imgs/banner1.jpg" style={{ width: "100%" }} />
        <div className="dp-slider-text">
          <Text
            customClass="text-weight-600"
            text="Time to Sky-Rocket Your Sales"
            color="white"
            variant="36"
          />
          <Text
            customClass="text-weight-600"
            text={[
              <span>
                Best Digital Marketing <span className="d-block">Agency</span>
              </span>,
            ]}
            color="white"
            fontSize="66"
            lineHeight="66"
          />
          <Button
            text="GET IN TOUCH"
            btnBg="primary"
            customClass="mt-4"
            btnOnClick={() => navigate("/contactus")}
          />
        </div>
      </div>

      {/* <div className="slider-bottom mt-n5">
        <div className="row">
          <div className="col-lg-3  pr-lg-1">
            <div className="bg-primary slider-bottom-cards d-flex align-items-center justify-content-center p-1">
              <div className='slider-bottom-cards-left'>
                <img src='/imgs/sliderIco1.svg' />
              </div>
              <div className='ml-3'>
                <Text
                  customClass="text-weight-600"
                  text="Free  Click &amp; Collect"
                  color='white'
                  variant="22"
                />
                <Text
                  text="From our 3 stores"
                  color='white'
                  variant="description"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-3 pl-lg-0 pr-lg-1">
            <div className="bg-primary slider-bottom-cards d-flex align-items-center justify-content-center p-1">
              <div className='slider-bottom-cards-left'>
                <img src='/imgs/sliderIco2.svg' />
              </div>
              <div className='ml-3'>
                <Text
                  customClass="text-weight-600"
                  text="Finance Available* "
                  color='white'
                  variant="22"
                />
                <Text
                  text="Up to 48months, subject to status,"
                  color='white'
                  variant="description"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-3 pl-lg-0 pr-lg-1">
            <div className="bg-primary slider-bottom-cards d-flex align-items-center justify-content-center p-1">
              <div className='slider-bottom-cards-left'>
                <img src='/imgs/sliderIco3.svg' />
              </div>
              <div className='ml-3'>
                <Text
                  customClass="text-weight-600"
                  text="Book A Service Online"
                  color='white'
                  variant="22"
                />
                <Text
                  text="Workshops in every store"
                  color='white'
                  variant="description"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-3 pl-lg-0 ">
            <div className="bg-primary slider-bottom-cards d-flex align-items-center justify-content-center p-1">
              <div className='slider-bottom-cards-left'>
                    <StarsRating
                        count={5}
                        value={4.5}
                        edit={false}
                        size={24}
                        color2={"#FF9900"}
                  />
              </div>
              <div className='ml-3'>
                <Text
                  customClass="text-weight-600"
                  text="Excellent"
                  color='white'
                  variant="22"
                />
                <Text
                  text="Turstpilot"
                  color='white'
                  variant="description"
                />
              </div>
            </div>
          </div>

        </div>

      </div> */}

      <section className="service mt-5 ">
        <div className="container ">
          <div className="row">
            <div className="col-md-6 mx-auto text-center">
              <Text
                customClass="font-secondary text-weight-600 text-uppercase "
                text="Our Services"
                variant="36"
              />
              <Text
                customClass=""
                text="Lorem orci elementum dictum viverra sed lobortis vitae purus Vitae eget 
                                cursus dictum ac tellus faucibus Porta aliquet"
                variant="16"
              />
            </div>
          </div>
          <div className="row mt-5">
            {service?.map((item) => (
              <div className="col-md-4 mb-4">
                <div
                  className="serviceCard box-shadow h-100 text-center p-5 mt-4 cursor-pointer service"
                  onClick={() => navigate(`/services/${item.slug}`)}
                >
                  <img src={`${config.config.imgurl}${item.image}`} />
                  <Text
                    customClass="font-secondary text-weight-600 text-uppercase my-2 text-primary"
                    text={item.type}
                    variant="26"
                  />
                  {/* {parse(item?.description)} */}
                  <Text text={parse(item?.description)} variant="16" />
                </div>
              </div>
            ))}
          </div>
          {/* <Button text="VIEW DETAIL" btnBg="primary" customClass="mt-4 " btnOnClick={() => navigate('/service-detail')}/> */}
        </div>
      </section>

      <section className="financeSlider mt-5 bg-black">
        <div className="container-fluid px-0">
          <div className="row text-center align-items-center">
            <div className="col-lg-3 text-left pl-0">
              <img src="/imgs/slider11.png" />
            </div>
            <div className="col-lg-6 pr-0">
              <Swiper
                modules={[Navigation, Pagination, Scrollbar, Autoplay]}
                spaceBetween={10}
                slidesPerView={1}
                navigation={false}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
              >
                <SwiperSlide className="">
                  <div className="financeSlider-Card d-flex align-items-start justify-content-center">
                    <div className="financeSlider-Card-left">
                      <Text
                        text="100%"
                        color="white"
                        fontSize="120"
                        customClass=" mr-3"
                      />
                    </div>
                    <div className="financeSlider-Card-right text-left">
                      <Text
                        text="Trusted"
                        color="white"
                        fontSize="85"
                        customClass="  text-uppercase text-weight-600"
                      />
                      <Text
                        text="RD Digital"
                        color="white"
                        variant="30"
                        customClass="available text-uppercase"
                      />
                    </div>
                  </div>
                  <Text
                    text="Boost Sales the Easy Way"
                    color="white"
                    fontSize="30"
                    customClass="text-weight-600"
                  />
                </SwiperSlide>
                <SwiperSlide className="">
                  <div className="financeSlider-Card d-flex align-items-start justify-content-center">
                    <div className="financeSlider-Card-left">
                      <Text
                        text="100%"
                        color="white"
                        fontSize="120"
                        customClass=" mr-3"
                      />
                    </div>
                    <div className="financeSlider-Card-right text-left">
                      <Text
                        text="Trusted"
                        color="white"
                        fontSize="85"
                        customClass="  text-uppercase text-weight-600"
                      />
                      <Text
                        text="RD Digital"
                        color="white"
                        variant="30"
                        customClass="available text-uppercase"
                      />
                    </div>
                  </div>
                  <Text
                    text="Reach Out To The Right Audience"
                    color="white"
                    fontSize="30"
                    customClass="text-weight-600"
                  />
                </SwiperSlide>
              </Swiper>
            </div>
            <div className="col-lg-3 text-right">
              <img src="/imgs/finance-right.png" />
            </div>
          </div>
        </div>
      </section>

      <section className="contact-form mt-5">
        <div className="container ">
          <div className="row mt-4 ">
            <div className="col-lg-7">
              <div className="row contact-form-heading mb-4">
                <div className="col-md-8 text-left">
                  <h1 className="text-title-main font-secondary text-weight-800  mb-1">
                    Keep <span className="text-primary">In Touch.</span>{" "}
                  </h1>
                  <Text
                    customClass="contact-form-heading-descrip"
                    text="You can reach us by any of the following means or alternatively  fill in the form below"
                    variant="16"
                  />
                </div>
              </div>
              <form
                className="row common-form common-form-border common-form-bg"
                onSubmit={handleSubmit}
              >
                <div className="col-lg-6 mb-3 pr-0">
                  <input
                    type="text"
                    placeholder="First Name"
                    value={values.firstName}
                    name="firstName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="col-lg-6 mb-3">
                  <input
                    type="text"
                    placeholder="Last Name"
                    value={values.lastName}
                    name="lastName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="col-lg-6 mb-3 pr-0">
                  <input
                    type="text"
                    placeholder="Email"
                    value={values.email}
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="col-lg-6 mb-3">
                  <input
                    type="text"
                    placeholder="Phone Number"
                    value={values.phoneNumber}
                    name="phoneNumber"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="col-lg-12 mb-3">
                  {service?.length > 0 && (
                    <select
                      value={values.service}
                      name="service"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option>select service </option>

                      {service.map((data) => {
                        return <option value={data.type}>{data.type}</option>;
                      })}
                    </select>
                  )}
                </div>
                <div className="col-lg-12 mb-3">
                  <textarea
                    placeholder="comment"
                    name="comment"
                    onChange={handleChange}
                    style={{ height: "120px" }}
                  ></textarea>
                </div>
                <div className="col-lg-12 mb-3">
                  <Button
                    customClass="w-50"
                    text="Submit"
                    btnBg="primary"
                    btnOnClick={(e) => {
                      e.preventDefault();
                      handleSubmit();
                    }}
                  />
                </div>
              </form>
            </div>
            <div className="col-md-4 ">
              <div className="contact-us-ads p-5">
                <div className="">
                  <Card
                    imgSrc="/imgs/contact3.jpg"
                    customClass="mt-2 text-center"
                    childClass="mb-4 px-2 text-center w-100"
                    overlay="none"
                    childAlign="center"
                  >
                    {/* <Text
                                            customClass="text-uppercase text-weight-800 font-secondary"
                                            text="Product of Trending"
                                            variant="22"
                                        />
                                        <Text
                                            customClass="text-uppercase text-weight-600 font-secondary"
                                            text="MOUTAIN BIKE"
                                            fontSize="54"
                                        />
                                        <Text
                                            customClass="text-uppercase text-weight-700 font-secondary"
                                            text="Sale"
                                            fontSize="80"
                                            lineHeight="70"
                                        />
                                        <Text
                                            customClass="text-uppercase text-weight-700 font-secondary"
                                            text="50%"
                                            fontSize="100"
                                            lineHeight="100"
                                        />
                                        <Button
                                            customClass="font-secondary text-uppercase mt-5"
                                            text="Contact Us"
                                            color="white"
                                            fontSize="22"
                                        /> */}
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mt-5 d-flex align-items-center testimonials">
        <div className="container ">
          <div className="row align-items-center">
            <div className="col-lg-6"></div>
            <div className="col-lg-6 text-center"></div>
            {/* <div className="col-lg-3">
           </div> */}
            <div className="row align-items-center">
            <div className="col-lg-6">
            <div className="">
                <Text
                  customClass="text-title-main font-secondary text-weight-900 text-uppercase mb-3 text-primary"
                  text="Testimonials"
                  variant="36"
                />
                <Text
                customClass="text-title-main font-secondary text-weight-500  mb-3"
                  text="Check out what our clients have said about us"
                  variant="36"
                />
              </div>
              </div>
              <div className="col-lg-6">
                <div className="bg-pink px-4 py-3 radius-20">
                  <Swiper
                    modules={[Navigation, Pagination, Scrollbar, Autoplay]}
                    navigation
                    spaceBetween={2}
                    slidesPerView={1}
                    onSwiper={(swiper) => console.log(swiper)}
                    onSlideChange={() => console.log("slide change")}
                    autoplay={{
                      delay: 2500,
                      disableOnInteraction: false,
                    }}
                    className="bikeFitting-review arrowPrimary"
                  >
                    {testimonials &&
                      testimonials.map((item, i) => {
                        return (
                          <SwiperSlide className="sliderr">
                            <StarsRating
                              count={5}
                              value={5}
                              edit={true}
                              size={24}
                              color1={"#000"}
                              color2={"#CE9400"}
                            />
                            <div className="text-title-16 mt-2 d-flex align-items-center text-weight-700 w-100">
                              <span className="text-title-19">
                                {item.clientName},{" "}
                              </span>
                              <span>{item.service}</span>
                            </div>
                            <Text
                              customClass="mt-2"
                              text={[
                                <span>
                                  {item.description.replace(
                                    /<\/?[^>]+(>|$)/g,
                                    ""
                                  )}{" "}
                                </span>,
                              ]}
                            />
                            <div className="text-title-16 mt-5 d-flex align-items-center justify-content-end text-weight-700 w-100">
                              <span>Date, </span>
                              <span>{getDate(item.creationDate)}:</span>
                            </div>
                          </SwiperSlide>
                        );
                      })}
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="home-about text-center mt-5 ">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="text-title-main font-secondary text-weight-800 text-uppercase mb-3">
                ABOUT <span className="text-primary">R D Digital</span>{" "}
              </h1>
              <Text
                customClass="mb-4"
                text={description1}
                variant="descrption"
              />

              <Button
                text="Read More"
                btnBg="primary"
                btnOnClick={() => navigate("/about")}
              />
            </div>
          </div>
        </div>
      </section>

      <div className="container py-4 mt-4">
        <div style={{ borderTop: "1px solid #ddd" }}></div>
        <div className="row ">
          <div className={` col-md-6 text-center mx-auto my-4`}>
            <Newsletter />
          </div>
        </div>
      </div>

      <div className="mt-5"></div>
    </>
  );
}

export default Home;
