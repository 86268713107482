import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Button from "../components/Button";
import BreadCrumb from "../components/Breadcrumb";
import Text from "../components/Text";
import Newsletter from "../components/Newsletter";
import Input from "../components/Input";
import CheckBox from "../components/CheckBox";
import { useFormik } from "formik";
import { formSchema } from "../components/Schemas";
import PasswordChecklist from "react-password-checklist";
import { config } from "../config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import axios from "axios";

function Signup() {
  const initialValues = {
    firstName: "",
    lastName: "",
    country: "",
    postalCode: "",
    telePhone: "",
    mobile: "",
    email: "",
  };

  const [state, setState] = React.useState({
    Mr: false,
    Mrs: false,
    Ms: false,
    Miss: false,
    Dr: false,
    Prof: false,
  });

  const [countryCode, setcountryCode] = useState("");
  const [mobile, setmobile] = useState("");
  const [value, setValue] = useState();
  const [agree, setAgree] = useState(false);
  const [passType, setPassType] = useState("password");
  const [icoSrc, setIcoSrc] = useState("eyeHide");
  const [error, seterror] = useState(false);
  const [PassValid, setPassValid] = useState(false);
  const [Title, setTitle] = useState("");

  const {
    values,
    errors,
    handleBlur,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    // validationSchema: formSchema,
    onSubmit: (values) => {
      hitApi();
    },
  });

  // const valid = (e) => {
  //   e.preventDefault();
  //   if (
  //     state.Mr ||
  //     state.Mrs ||
  //     state.Prof ||
  //     state.Ms ||
  //     state.Miss ||
  //     state.Dr
  //   ) {
  //     seterror(false);
  //   } else {
  //     seterror(true);
  //   }
  //   if (!PassValid) {
  //     console.log(PassValid);
  //     seterror(true);
  //   } else {
  //     console.log(PassValid);
  //     seterror(false);
  //   }
  // };

  const hitApi = async () => {
  
      let data = {};

      if(values.firstName){
        data["firstName"] = values.firstName
      }
      if(values.lastName){
        data["lastName"] = values.lastName
      }
      if(values.country){
        data["country"] = values.country
      }
      if(values.telePhone){
        data["telePhone"] = values.telePhone
      }
      if(values.email){
        data["email"] = values.email
      }
      if(countryCode){
        data["postalCode"] = countryCode
      }
      if(mobile){
        data["phoneNumber"] = mobile
      }
      
      try {
        const response = await axios.post(
          `${config.siteURL}contact/`,
          data
        );
        toast(response.data.data.message);
        let resetValues = values;

        resetValues["firstName"] = "";
        resetValues["lastName"] = "";
        resetValues["country"] = "";
        resetValues["postalCode"] = "";
        resetValues["telePhone"] = "";
        resetValues["mobile"] = "";
        resetValues["email"] = "";
        setFieldValue(resetValues);
        // window.location.reload()
      } catch (error) {
        console.log(error)
        // toast(error.response.data.data.message);
      }
    
  };

  const handleCountryCode = (value, data, event, formattedValue) => {
    if (data.dialCode) {
      console.log(data.dialCode, value.slice(data.dialCode.length), "ana");

      setcountryCode(data.dialCode);
      setmobile(value.slice(data.dialCode.length));
    }
  };

  const handleChange1 = (e) => {
    console.log("ana", e.target.name);
    // setAgree(!agree);

    if (e.target.name == "Mr") {
      setState({
        ...state,
        Mr: true,
        Mrs: false,
        Ms: false,
        Miss: false,
        Dr: false,
        Prof: false,
      });
      setTitle(e.target.name);
    }
    if (e.target.name == "Mrs") {
      setState({
        ...state,
        Mr: false,
        Mrs: true,
        Ms: false,
        Miss: false,
        Dr: false,
        Prof: false,
      });
      setTitle(e.target.name);
    }
    if (e.target.name == "Ms") {
      setState({
        ...state,
        Mr: false,
        Mrs: false,
        Ms: true,
        Miss: false,
        Dr: false,
        Prof: false,
      });
      setTitle(e.target.name);
    }
    if (e.target.name == "Miss") {
      setState({
        ...state,
        Mr: false,
        Mrs: false,
        Ms: false,
        Miss: true,
        Dr: false,
        Prof: false,
      });
      setTitle(e.target.name);
    }
    if (e.target.name == "Dr") {
      setState({
        ...state,
        Mr: false,
        Mrs: false,
        Ms: false,
        Miss: false,
        Dr: true,
        Prof: false,
      });
      setTitle(e.target.name);
    }
    if (e.target.name == "Prof") {
      setState({
        ...state,
        Mr: false,
        Mrs: false,
        Ms: false,
        Miss: false,
        Dr: false,
        Prof: true,
      });
      setTitle(e.target.name);
    }
  };

  const changeAgree = () => {
    setAgree(!agree);
  };

  return (
    <>
      <ToastContainer />
      <BreadCrumb
        customClass="mr-5 my-2"
        menuActive="Contact"
        align="end"
        color="black"
      />
      <section className="signup-form loginSignup-form py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 ml-auto">
              <div className="loginSignup-form-box p-5 radius-5 box-shadow">
                <div className="row mb-4 px-0 px-xl-4">
                  <div className="col-md-8 ">
                    <Text
                      customClass="text-weight-700 "
                      text="Contact us"
                      variant="36"
                      color="black"
                    />
                    <Text
                      customClass=" "
                      text="Required fields are indicated with *"
                      variant="16"
                      color="black"
                    />
                    <div className="headingDivider bg-primary"></div>
                  </div>
                </div>
                <form
                  className="row common-form common-form-borderless common-form-bg px-0 px-xl-4"
                  onSubmit={handleSubmit}
                >
                  <div className="col-md-12">
                    <Text
                      customClass="text-weight-600 mb-2"
                      text="Title *"
                      variant="18"
                      color="black"
                    />
                  </div>
                  <div className="col-md-12 mb-4">
                    <div className="signup-form-checkbox row">
                      <div className="col-md-2">
                        <CheckBox
                          id="reg1"
                          label="Mr"
                          customClass="signUp-checkbox pr-2"
                          childClass="signUp-checkbox-child"
                          labelClass="pl-2"
                          name={"Mr"}
                          checked={state.Mr}
                          onChange={handleChange1}
                        >
                          <span>|</span>
                        </CheckBox>
                      </div>
                      <div className="col-md-2">
                        <CheckBox
                          id="reg2"
                          label="Mrs"
                          customClass="signUp-checkbox pr-2"
                          childClass="signUp-checkbox-child"
                          labelClass="pl-2"
                          checked={state.Mrs}
                          onChange={handleChange1}
                          name={"Mrs"}
                        >
                          <span>|</span>
                        </CheckBox>
                      </div>
                      <div className="col-md-2">
                        <CheckBox
                          id="reg3"
                          label="Ms"
                          customClass="signUp-checkbox pr-2"
                          childClass="signUp-checkbox-child"
                          labelClass="pl-2"
                          checked={state.Ms}
                          onChange={handleChange1}
                          name={"Ms"}
                        >
                          <span>|</span>
                        </CheckBox>
                      </div>
                      <div className="col-md-2">
                        <CheckBox
                          id="reg4"
                          label="Miss"
                          customClass="signUp-checkbox pr-2"
                          childClass="signUp-checkbox-child"
                          labelClass="pl-2"
                          checked={state.Miss}
                          onChange={handleChange1}
                          name={"Miss"}
                        >
                          <span>|</span>
                        </CheckBox>
                      </div>
                      <div className="col-md-2">
                        <CheckBox
                          id="reg5"
                          label="Dr"
                          customClass="signUp-checkbox pr-2"
                          childClass="signUp-checkbox-child"
                          labelClass="pl-2"
                          checked={state.Dr}
                          onChange={handleChange1}
                          name={"Dr"}
                        >
                          <span>|</span>
                        </CheckBox>
                      </div>
                      <div className="col-md-2">
                        <CheckBox
                          id="reg6"
                          label="Prof"
                          customClass="signUp-checkbox pr-2"
                          childClass="signUp-checkbox-child"
                          labelClass="pl-2"
                          checked={state.Prof}
                          onChange={handleChange1}
                          name={"Prof"}
                        ></CheckBox>
                      </div>
                    </div>
                    {error ? (
                      <span style={{ fontWeight: "bold", color: "red" }}>
                        {Title ? "" : "select title"}
                      </span>
                    ) : null}
                  </div>
                  <div className="col-lg-6 mb-3 pr-0">
                    <label className="text-weight-600" for="f-name">
                      First Name *
                    </label>
                    <input
                      className="box-shadow mt-2"
                      id="f-name"
                      type="text"
                      placeholder="First Name"
                      name="firstName"
                      value={values.firstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.firstName && touched.firstName ? (
                      <span style={{ fontWeight: "bold", color: "red" }}>
                        {errors.firstName}
                      </span>
                    ) : null}
                  </div>
                  <div className="col-lg-6 mb-3 pr-0">
                    <label className="text-weight-600" for="l-name">
                      Last Name *
                    </label>
                    <input
                      className="box-shadow mt-2"
                      id="l-name"
                      type="text"
                      placeholder="Last Name"
                      value={values.lastName}
                      name="lastName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.lastName && touched.lastName ? (
                      <span style={{ fontWeight: "bold", color: "red" }}>
                        {errors.lastName}
                      </span>
                    ) : null}
                  </div>

                  <div className="col-lg-6 mb-3 pr-0">
                    <label className="text-weight-600" for="County">
                      Country *{" "}
                    </label>
                    <input
                      className="box-shadow mt-2"
                      id="Country"
                      type="text"
                      placeholder="Country"
                      value={values.country}
                      name="country"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.country && touched.country ? (
                      <span style={{ fontWeight: "bold", color: "red" }}>
                        {errors.country}
                      </span>
                    ) : null}
                  </div>

                  <div className=" col-lg-6 mb-3 pr-0">
                    <label className="text-weight-600" for="Telephone">
                      Telephone *{" "}
                    </label>
                    <input
                      className="box-shadow mt-2"
                      id="Telephone"
                      type="text"
                      placeholder="Telephone"
                      value={values.telePhone}
                      name="telePhone"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.telePhone && touched.telePhone ? (
                      <span style={{ fontWeight: "bold", color: "red" }}>
                        {errors.telePhone}
                      </span>
                    ) : null}
                  </div>
                  <div className="col-lg-6 mb-3 pr-0">
                    <label className="text-weight-600" for="Mobile">
                      Mobile *{" "}
                    </label>

                    <PhoneInput
                      className="box-shadow mt-2"
                      inputClass="w-100"
                      name="countryCode"
                      placeholder="Enter phone number"
                      value={countryCode}
                      onChange={handleCountryCode}
                    />
                    {/* <input className="box-shadow mt-2" id="Mobile" type="text" placeholder="Mobile" value={values.mobile}
                                            name="mobile" maxLength={10} onChange={handleChange} onBlur={handleBlur} />
                                        {errors.mobile && touched.mobile ? <span style={{ fontWeight: 'bold', color: 'red', }}>{errors.mobile}</span> : null} */}
                  </div>
                  <div className="col-lg-6 mb-3 pr-0">
                    <label className="text-weight-600" for="email">
                      Email
                    </label>
                    <input
                      className="box-shadow mt-2"
                      id="email"
                      type="text"
                      placeholder="Email"
                      value={values.email}
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.email && touched.email ? (
                      <span style={{ fontWeight: "bold", color: "red" }}>
                        {errors.email}
                      </span>
                    ) : null}
                  </div>

                  {/* <label>Password:</label>
                                    <input type="password" onChange={e => setPassword(e.target.value)} /> */}

                  <div className="col-lg-12 mb-3  pr-0">
                    <Button
                      customClass="w-50"
                      text="Submit "
                      btnBg="primary"
                      // disabled={!agree}
                      btnOnClick={(e) => {
                        e.preventDefault();
                        handleSubmit();
                      }}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ======= NewsLetter ======== */}

      <div className="container py-4 mt-4">
        <div style={{ borderTop: "1px solid #ddd" }}></div>
        <div className="row ">
          <div className={` col-md-6 text-center mx-auto my-4`}>
            <Newsletter />
          </div>
        </div>
      </div>

      {/* ======= NewsLetter ======== */}
    </>
  );
}

export default Signup;
