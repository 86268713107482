module.exports = {

    // config: {
    //     imgurl: "http://127.0.0.1:8080/image/",
    //     siteURL: "http://127.0.0.1:8080/api/",
    // }

    config: {
        imgurl: "http://64.227.147.7:8080/image/",
        siteURL: "http://64.227.147.7:8080/api/",
    }
};
// 64.227.147.7