import { Link } from "react-router-dom";
import Button from "../components/Button";


import HeaderImg from '../components/HeaderImg'
import BreadCrumb from '../components/Breadcrumb'
import Text from '../components/Text'
import Card from '../components/ImgCard'
import Newsletter from '../components/Newsletter'

function Contact() {


    return (
        <>
          
           <HeaderImg 
                title="Contact Us"
                imgSrc="/imgs/contact.jpg" 
                align="center"
           >
                <BreadCrumb 
                    menuActive="Contact"
                    align="center"
                />
           </HeaderImg>
            <section className='contact-form mt-5 '>
                <div className='container '>

                    <div className="row mt-4 ">
                        <div className="col-lg-7">
                            <div className="row contact-form-heading mb-4">
                                <div className="col-md-8 text-left">
                                    <h1 className='text-title-main font-secondary text-weight-800  mb-1'>Keep  <span className='text-primary'>In Touch.</span> </h1>
                                    <Text
                                        customClass="contact-form-heading-descrip"
                                        text="You can reach us by any of the following means or alternatively  fill in the form below"
                                        variant="16"
                                    />
                                </div>
                            </div>
                            <form className="row common-form common-form-border common-form-bg">
                                <div className="col-lg-6 mb-3 pr-0">
                                    <input type="text" placeholder="First Name" />
                                </div>
                                <div className="col-lg-6 mb-3">
                                    <input type="text" placeholder="Last Name" />
                                </div>
                                <div className="col-lg-6 mb-3 pr-0">
                                    <input type="text" placeholder="Email" />
                                </div>
                                <div className="col-lg-6 mb-3">
                                    <input type="text" placeholder="Phone Number" />
                                </div>
                                <div className="col-lg-12 mb-3">
                                    <select>
                                        <option>Store Location </option>
                                        <option>Store 1 </option>
                                        <option>Store 2 </option>
                                        <option>Store 3 </option>
                                    </select>
                                </div>
                                <div className="col-lg-12 mb-3">
                                    <textarea placeholder="comment" style={{ height: "120px" }}></textarea>
                                </div>
                                <div className="col-lg-12 mb-3">
                                    <Button
                                        customClass="w-50"
                                        text="Submit"
                                        btnBg="primary"
                                    />
                                </div>
                            </form>
                        </div>
                        <div className="col-md-4 ">
                            <div className="contact-us-ads p-5">
                                <div className="">
                                    <Card
                                        imgSrc="/imgs/contactus-ads.jpg"
                                        customClass="mt-2 text-center"
                                        childClass="mb-4 px-2 text-center w-100"
                                        overlay="none"
                                        childAlign="center"
                                    >
                                        <Text
                                            customClass="text-uppercase text-weight-800 font-secondary"
                                            text="Product of Trending"
                                            variant="22"
                                        />
                                        <Text
                                            customClass="text-uppercase text-weight-600 font-secondary"
                                            text="MOUTAIN BIKE"
                                            fontSize="54"
                                        />
                                        <Text
                                            customClass="text-uppercase text-weight-700 font-secondary"
                                            text="Sale"
                                            fontSize="80"
                                            lineHeight="70"
                                        />
                                        <Text
                                            customClass="text-uppercase text-weight-700 font-secondary"
                                            text="50%"
                                            fontSize="100"
                                            lineHeight="100"
                                        />
                                        <Button
                                            customClass="font-secondary text-uppercase mt-5"
                                            text="Contact Us"
                                            color="white"
                                            fontSize="22"
                                        />
                                    </Card>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='homepage-our-store text-center mt-5 mb-5'>
                <div className='container'>
                    <div className="row">
                        <div className="col-lg-4">
                            <Card
                                imgSrc="/imgs/london.jpg"
                                customClass="mt-2 text-center"
                                childClass="mb-4 text-center w-100"
                                overlay="default"
                                childAlign="center"
                            >
                                <Text
                                    text="London Store"
                                    variant="26"
                                    customClass="text-uppercase font-secondary text-weight-500"
                                />
                                <p className=''>3 - 4 London Bridge Walk <br className='d-none d-lg-block' /> London, SE1 2SX, United Kingdom</p>
                                <ul className='mt-3 text-weight-600'>
                                    <li>Phone No. : 020 7378 6669</li>
                                    <li><span>Directions</span></li>
                                </ul>

                                <div className='mt-4'>
                                    <Button text="Book A Rental" btnBg="primary" />
                                    <Button text="Store Destination" btnBg="white" color="primary" />
                                </div>
                                <div className='homepage-our-store-social mt-4' >
                                    <Link className='mr-2' to=""><img src='/imgs/fb.svg' /></Link>
                                    <Link className='mr-2' to=""><img src='/imgs/insta.svg' /></Link>
                                    <Link className='mr-2' to=""><img src='/imgs/tw.svg' /></Link>
                                    <Link className='' to=""><img src='/imgs/in.svg' /></Link>
                                </div>


                            </Card>
                        </div>
                        <div className="col-lg-4">
                            <Card
                                imgSrc="/imgs/birmingham.jpg"
                                customClass="mt-2 text-center"
                                childClass="mb-4 text-center w-100"
                                overlay="default"
                                childAlign="center"
                            >
                                <Text
                                    text="Birmingham store"
                                    variant="26"
                                    customClass="text-uppercase font-secondary text-weight-500"
                                />
                                <p className=''>92-Boldmere Rd, Boldmere, <br className='d-none d-lg-block' /> Sutton Coldfield, B73 5UB,</p>
                                <ul className='mt-3 text-weight-600'>
                                    <li>Phone No. : 0121 666 6933</li>
                                    <li><span>Directions</span></li>
                                </ul>

                                <div className='mt-4'>
                                    <Button text="Book A Rental" btnBg="primary" />
                                    <Button text="Store Destination" btnBg="white" color="primary" />
                                </div>
                                <div className='homepage-our-store-social mt-4' >
                                    <Link className='mr-2' to=""><img src='/imgs/fb.svg' /></Link>
                                    <Link className='mr-2' to=""><img src='/imgs/insta.svg' /></Link>
                                    <Link className='mr-2' to=""><img src='/imgs/tw.svg' /></Link>
                                    <Link className='' to=""><img src='/imgs/in.svg' /></Link>
                                </div>


                            </Card>
                        </div>
                        <div className="col-lg-4">
                            <Card
                                imgSrc="/imgs/east.jpg"
                                customClass="mt-2 text-center"
                                childClass="mb-4 text-center w-100"
                                overlay="default"
                                childAlign="center"
                            >
                                <Text
                                    text="EAST Grinstead store"
                                    variant="26"
                                    customClass="text-uppercase font-secondary text-weight-500"
                                />
                                <p className=''>Felbridge Forge, <br className='d-none d-lg-block' /> London Road, West Sussex, RH19 2RQ</p>
                                <ul className='mt-3 text-weight-600'>
                                    <li>Phone No. : 01342 77 77 00</li>
                                    <li><span>Directions</span></li>
                                </ul>

                                <div className='mt-4'>
                                    <Button text="Book A Rental" btnBg="primary" />
                                    <Button text="Store Destination" btnBg="white" color="primary" />
                                </div>
                                <div className='homepage-our-store-social mt-4' >
                                    <Link className='mr-2' to=""><img src='/imgs/fb.svg' /></Link>
                                    <Link className='mr-2' to=""><img src='/imgs/insta.svg' /></Link>
                                    <Link className='mr-2' to=""><img src='/imgs/tw.svg' /></Link>
                                    <Link className='' to=""><img src='/imgs/in.svg' /></Link>
                                </div>


                            </Card>
                        </div>
                    </div>
                </div>
            </section>

            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4966.79434241191!2d-0.09085852912830131!3d51.50592905575681!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487603508835bc3b%3A0x8f0b1aa2b6c95dd9!2sOn%20Your%20Bike!5e0!3m2!1sen!2sin!4v1665059559295!5m2!1sen!2sin" width="100%" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

            {/* ======= NewsLetter ======== */}

            <div className="container py-4 mt-4">
                <div  style={{borderTop: "1px solid #ddd"}}></div>
                <div className="row ">
                    <div className={` col-md-6 text-center mx-auto my-4`}>
                        <Newsletter />
                    </div>
                </div>
            </div>

            {/* ======= NewsLetter ======== */}

        </>
    );
}

export default Contact