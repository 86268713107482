import React, { children } from 'react';


import './style.css'

function CheckBox(props) {

    return (
        <>
            <div className={`checkbox-group position-relative d-flex align-items-center ${props.customClass} ${props.align}`}>
                <input id={props.id} type="checkbox" className={` ${props.inputClass} m-0`} placeholder={props.placeholder} checked={props.checked} onChange={props.onChange} name={props.name} />
                <label for={props.id} className={`checkbox-group-label text-title-16 ${props.labelClass}`}>{props.label}</label>
                <div
                    className={`checkbox-group-children position-absolute ${props.childClass} `}
                >
                    {props.children}
                </div>
            </div>
        </>

    )
}
export default CheckBox