import React, { useEffect, useState, useRef } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import {
  Button,
  Table,
  Tab,
  Tabs,
  Row,
  Col,
  Alert,
  Container,
  Form,
  label,
  Accordion,
} from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Style from "./AdminStyle";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Sidebar from "./Sidebar";
import NavBar from "./NavBar";
import $ from "jquery";

import { useFormik } from "formik";
// import { createCategorySchema } from "../Schemas";
import upload from "../../assets/images/uploadIcon.svg";
import noimage from "../../assets/images/noImage.jpg";
import plus from "../../assets/images/plus.svg";
import { ToastContainer, toast } from "react-toastify";
import { config } from "../../config";
import axios from "axios";
import UploadImages from "../Upload";
window.jQuery = window.$ = $;
require("jquery-nice-select");
function AddTestimonials() {
  const navigate = useNavigate();

  const initialValues = {
    clientName: "",
    service: "",
    description: "",
    image: [],
  };

  const selectRef1 = useRef();
  useEffect(() => {
    $(selectRef1.current).niceSelect();
  }, []);
  const selectRef2 = useRef();
  useEffect(() => {
    $(selectRef2.current).niceSelect();
  }, []);
  const selectRef3 = useRef();
  useEffect(() => {
    $(selectRef3.current).niceSelect();
  }, []);
  const [noOfRows, setNoOfRows] = useState(1);

  const {
    values,
    errors,
    handleBlur,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    // validationSchema: createCategorySchema,
    onSubmit: (values) => {
      console.log("vvvvvvv");
      createTestimonials(values);
    },
  });

  const createTestimonials = async (values) => {
    const formData = new FormData();

    // Append other key-value pairs from the 'values' object to the FormData
    Object.entries(values).forEach(([key, val]) => {
      // If it's an array of files, append each file
      if (Array.isArray(val)) {
        val.forEach((file, index) => {
          formData.append("files", file, file.name);
        });
      } else {
        formData.append(key, val);
      }
    });

    try {
      const response = await axios.post(
        `${config.siteURL}testimonial/`,
        formData
      );
      //  toast(response.data.data.message)
      navigate("/admin/TestimonialsList");
    } catch (error) {
      toast(error.response.data.data.message);
    }
  };

  const setImages = (file) => {
    setFieldValue("image", file);
  };

  return (
    <>
      <ToastContainer />
      <Style />
      <article id="root" className="mainRoot">
        <div className="wrapper">
          <div className="sidebar">
            <Sidebar />
          </div>
          <div className="content-page">
            <div className="content">
              <div className="MainNavRow">
                <NavBar />
              </div>
              <div className="container-fluid  mt-4">
                <div className="cardFull  Bgwhite Shadow radius20 p-4 mx-4">
                  <div className="addPrdctRow">
                    <div className="MainHdng">
                      <h3>Add Testimonials</h3>
                    </div>
                    <div class="hrStyle pb-5">
                      <hr />
                    </div>
                    <div className="addAcordion">
                      <form class="formStyle addFormStyle" action="#">
                        <div class="row row d-flex align-items-center ">
                          <div className="col-sm-2 text-end">
                            <Form.Label required>Client Name </Form.Label>
                          </div>

                          <div class="col">
                            <Form.Control
                              type="text"
                              placeholder="Enter Client Name"
                              value={values.clientName}
                              name="clientName"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.clientName && touched.clientName ? (
                              <span
                                style={{ fontWeight: "bold", color: "red" }}
                              >
                                {errors.clientName}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div class="row row d-flex align-items-center ">
                          <div className="col-sm-2 text-end">
                            <Form.Label required>Service </Form.Label>
                          </div>

                          <div class="col">
                            <Form.Control
                              type="text"
                              placeholder="Enter Service Name"
                              value={values.service}
                              name="service"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.service && touched.service ? (
                              <span
                                style={{ fontWeight: "bold", color: "red" }}
                              >
                                {errors.service}
                              </span>
                            ) : null}
                          </div>
                        </div>

                        <div class=" mb-15">
                          <div class="row row d-flex align-items-center mt-4">
                            <div className="col-sm-2 text-end">
                              <label class="form-label" for="input5">
                                Description{" "}
                                <span class="contact__form--label__star">
                                  *
                                </span>
                              </label>
                            </div>
                            <div class="col">
                              <div className="ckEditor">
                                <CKEditor
                                  editor={ClassicEditor}
                                  data="<p>Description</p>"
                                  onReady={(editor) => {
                                    // You can store the "editor" and use when it is needed.
                                    console.log(
                                      "Editor is ready to use!",
                                      editor
                                    );
                                  }}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setFieldValue("description", data);
                                    console.log(values, "taa");
                                  }}
                                  onBlur={(event, editor) => {
                                    console.log("Blur.", editor);
                                  }}
                                  onFocus={(event, editor) => {
                                    console.log("Focus.", editor);
                                  }}
                                />
                              </div>
                              {errors.description && touched.description ? (
                                <span
                                  style={{ fontWeight: "bold", color: "red" }}
                                >
                                  {errors.description}
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="mb-15 mt-4">
                          <UploadImages
                            image={values?.image}
                            setImages={setImages}
                          />
                        </div>
                        <div className="SeoForm">
                          <div className="row d-flex align-items-center my-3 mb-4">
                            <div className="col-sm-2 text-end">
                              <Form.Label>Meta Tag Title </Form.Label>
                            </div>
                            <div class="col">
                              <input
                                type="text"
                                name="product_description[1][meta_title]"
                                placeholder="Meta Tag Title"
                                id="input-meta-title1"
                                class="form-control"
                              />
                            </div>
                          </div>
                          <div className="row d-flex align-items-center my-3  mb-4">
                            <div className="col-sm-2 text-end">
                              <Form.Label>Meta Tag Description </Form.Label>
                            </div>
                            <div class="col">
                              <textarea className="form-control"></textarea>
                            </div>
                          </div>
                          <div className="row d-flex align-items-center my-3 ">
                            <div className="col-sm-2 text-end">
                              <Form.Label>Meta Tag Keywords </Form.Label>
                            </div>
                            <div class="col">
                              <textarea className="form-control"></textarea>
                            </div>
                          </div>
                        </div>
                        <div className="row d-flex align-items-center my-3  mb-4">
                          <div className="col-sm-2 text-end"></div>
                          <div class="col">
                            <button
                              class="btnCommon"
                              type="submit"
                              onClick={handleSubmit}
                            >
                              Continue
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </>
  );
}
export default AddTestimonials;
