import { Link } from "react-router-dom";
import {useState} from "react"
import { Row, Col, Alert, Container, Form ,label} from "react-bootstrap"
import Style from './AdminStyle';
import logo from "../../assets/images/LogoLg.svg";

function Otp() {  

  const [passType, setPassType] = useState("password");
    const [icoSrc, setIcoSrc] = useState("eyeHide");
  const showHideFun = () => {
        if (passType === "password" && icoSrc === "eyeHide") {
            setPassType("text")
            setIcoSrc("eyeShow")
        } else {
            setPassType("password")
            setIcoSrc("eyeHide")
        }
    }

     return (
      <>
      <Style/>
    <div className="auth-page">
    <Container fluid className="p-0">
      <Row className="g-0">
        <Col lg={4} md={5} className="col-xxl-3">
          <div className="auth-full-page-content d-flex p-sm-5 p-4">
            <div className="w-100">
              <div className="d-flex flex-column h-100">
                <div className="mb-4 mb-md-5 text-center">
                  <Link to="/admin/login" className="d-block auth-logo">
                    <img src={logo} alt="" height="53" />
                  </Link>
                </div>
                <div className="auth-content my-auto">
                  <div className="text-center">
                    <h5 className="mb-0">OTP VERIFY</h5>
                    <p className="text-muted mt-2">Enter your email address below and we'll send you One Time Password (OTP) to your email ID.</p>
                  </div>
                  <Form className="formStyle mt-4 pt-2">                  
                    <div className="row">
                        <div className="col-md-12">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="text" placeholder="Enter email" />  
                        </div>                                                  
                    </div>
                    <div className="mb-3">
                        <Link to="" class="btnCommon mt-3  w-100 waves-effect waves-light" type="submit">Reqest OTP</Link>
                    </div>
                  </Form>      
                </div>
                <div className="mt-4 mt-md-5 text-center">
                  <p className="mb-0"> {new Date().getFullYear()} © R D Digital</p>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <div className="col-xxl-9 col-lg-8 col-md-7">
                <div className="auth-bg pt-md-5 p-4 d-flex">
                    <div className="bg-overlay bg-primary"></div>
                    <ul className="bg-bubbles">
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                    </ul>
                    <div className="row justify-content-center align-items-center">
                        <div className="col-xl-7">
                            <div className="p-0 p-sm-4 px-xl-0">                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      </Row>
    </Container>
  </div> 
  </> 
  );
}
export default Otp;  
