import React from "react";
import ScrollToTop from "react-scroll-to-top";
import './base.css';
import './custom.css';
import './awesome.css';
import { BrowserRouter as Router, Routes, Route , useNavigate } from "react-router-dom";
import Home from './pages/home'
import Contact from './pages/contact'
import Login from './pages/login'
import Signup from './pages/signup'
import ChangePassword from './pages/change-password'
import OTPverify from './pages/otp-verify'
import Service from './pages/service'
import ServiceDetail from './pages/service-detail'
import CycleToWork from './pages/cycle-to-work'
import Testimonials from './pages/testimonials'
import About from './pages/about'
import Direction from './pages/direction'
import Header from './components/Header'
import Footer from './components/Footer'
import Blogs from './pages/blogs'
import BlogDetail from './pages/blog-detail'


/*** */
import {
  AdDashboard,
  AdLogin,
  AdSidebar,
  AdminMyProfile,
  AdminEditProfile,
  AdminChangePassword,
  AdminAddServices,
  EditServices,
  ServicesList,
  ContactFeedbacks,
  Setting,
  HomePage,
  FaqAdmin,
  AddBlogCategory,
  EditBlogCategory,
  BlogCategoryList,
  AddBlog,
  AdminBlogList,
  EditBlog,
  Otp,
  ForgotPassword,
  CallList
  // ViewMerchant,
} from "./components";

import TestimonialsList from "./components/Admin/TestimonialsList";
import AddTestimonials from "./components/Admin/AddTestimonials";
import EditTestimonials from "./components/Admin/EditTestimonials";
import AddAbout from "./components/Admin/AddAbout";
import AboutList from "./components/Admin/AboutList";
import EditAbout from "./components/Admin/EditAbout";

let regex = new RegExp('/admin/')
const navigate = useNavigate

function App() {
  
  // const navigate = useNavigate()

  function PrivateRouteAdmin({ children }) {
    const authUser = JSON.parse(localStorage.getItem("userDetails"))
    if (!authUser.email) {
      console.log("abc")
      // not logged in so redirect to login page with the return url
      return navigate("/admin/login")
    }
    // authorized so return child components
    return children;
  }
  return (
    <>

      <Router>
        <ScrollToTop smooth />
        {regex.test(window.location.pathname) ?
          <Routes>
            <Route
              path="admin/dashboard"
              element={
                <PrivateRouteAdmin>
                  <AdDashboard />
                </PrivateRouteAdmin>
              }
            />
            <Route path="admin/login" element={<AdLogin />} />
            <Route path="admin/sidebar" element={<AdSidebar />} />
            <Route path="admin/otp" element={<Otp />} />
            <Route path="admin/ForgotPassword" element={<ForgotPassword />} />
            <Route path="admin/myprofile" element={<AdminMyProfile />} />
            <Route path="admin/EditProfile" element={<AdminEditProfile />} />
            <Route path="admin/ChangePassword" element={<AdminChangePassword />} />
            <Route path="admin/TestimonialsList" element={<TestimonialsList />} />
            <Route path="admin/AddTestimonials" element={<AddTestimonials/>} />
            <Route path="admin/EditTestimonials" element={<EditTestimonials/>} />
            <Route path="admin/AddServices" element={<AdminAddServices />} />
            <Route path="admin/EditServices" element={<EditServices />} />
            <Route path="admin/ServicesList" element={<ServicesList />} />
            <Route path="admin/AddAbout" element={<AddAbout />} />
            <Route path="admin/CallList" element={<CallList />} />
            <Route path="admin/ContactFeedbacks" element={<ContactFeedbacks />} />
            <Route path="admin/Setting" element={<Setting />} />
            <Route path="admin/HomePage" element={<HomePage />} />
            <Route path="admin/Faq" element={<FaqAdmin />} />
            <Route path="admin/AddBlogCategory" element={<AddBlogCategory />} />
            <Route path="admin/EditBlogCategory" element={<EditBlogCategory />} />
            <Route path="admin/BlogCategoryList" element={<BlogCategoryList />} />
            <Route path="admin/AddBlog" element={<AddBlog />} />
            <Route path="admin/BlogList" element={<AdminBlogList />} />
            <Route path="admin/EditBlog" element={<EditBlog />} />
            <Route path="admin/AboutList" element={<AboutList />} />
            <Route path="admin/EditAbout" element={<EditAbout />} />

          </Routes>
          :
          <>
            <Header />
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/services/:slug' element={<ServiceDetail />} />
              <Route exact path='/service' element={<Service />} />
              <Route path='/service-detail' element={< ServiceDetail />} />
              <Route path='/blogs' element={< Blogs />} />
              <Route path='/cycle-to-work' element={<CycleToWork />} />
              <Route path='/testimonials' element={<Testimonials />} />
              <Route path='/about' element={<About />} />
              <Route path='/direction' element={<Direction />} />
              <Route path='/contact' element={<Contact />} />
              <Route path='/login' element={<Login />} />
              <Route path='/login/:otp' element={<Login />} />
              <Route path='/contactus' element={<Signup />} />
              <Route path='/change-password' element={<ChangePassword />} />
              <Route path='/otp-verify' element={<OTPverify />} />
              <Route path='/blog-detail' element={< BlogDetail />} />
              
            </Routes>
            <Footer />
          </>
        }

      </Router>


    </>
  );
}

export default App;
