import React, { useEffect, useState, useRef } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import {
  Button,
  Table,
  Tab,
  Tabs,
  Row,
  Col,
  Alert,
  Container,
  Form,
  label,
  Accordion,
} from "react-bootstrap";
import Sidebar from "./Sidebar";
import NavBar from "./NavBar";
import $ from "jquery";
import Style from "./AdminStyle";
import plus from "../../assets/images/plus.svg";
import deleteIcon from "../../assets/images/icons/delete-icon.png";
import deleteWhite from "../../assets/images/icons/delete-white-icon.png";
import editIcon from "../../assets/images/icons/edit-icon.png";
import editWhite from "../../assets/images/icons/edit-white-icon.png";
import { PaginationControl } from "react-bootstrap-pagination-control";
import axios from "axios";
import config from "../../config";

window.jQuery = window.$ = $;
require("jquery-nice-select");
function BlogCategoryList() {
  const selectRef1 = useRef();
  useEffect(() => {
    $(selectRef1.current).niceSelect();
  }, []);
  const selectRef2 = useRef();
  useEffect(() => {
    $(selectRef2.current).niceSelect();
  }, []);
  const selectRef3 = useRef();
  useEffect(() => {
    $(selectRef3.current).niceSelect();
  }, []);

  const navigate = useNavigate();

  const [noOfRows, setNoOfRows] = useState(1);
  const [Data, setData] = useState([]);
  const [name, setName] = useState("");
  const [status, setStatus] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);

  const categoryList = async () => {
    try {
      const response = await axios.get(
        `${config.config.siteURL}blog/getAllCategoryBlogs?page=${page}&&limit=${limit}`
      );
      const List = response.data.response;
    //   toast(response.data.data.message);
      setData(List);
      setTotal(response.data.TotalCount);
    } catch (error) {
    //   toast(error.response.data.data.message);
    }
  };

  const onDeleteCategory = async (id) => {
    try {
      const res = await axios.delete(
        `${config.config.siteURL}blog/deleteBlogCategory/${id}`
      );
      categoryList();
    } catch (error) {}
  };

  const handleStatus = async (id, status) => {
    let data = {
      status: !status,
    };
    try {
      const res = await axios.patch(
        `${config.config.siteURL}blog/editBlogCategory/${id}`,
        data
      );
      categoryList()
        } catch (error) {}
  };

  useEffect(() => {
    categoryList();
  }, [page, limit, name, status]);

  return (
    <>
      <Style />

      <article id="root" className="mainRoot">
        <div className="wrapper">
          <div className="sidebar">
            <Sidebar />
          </div>
          <div className="content-page">
            <div className="content">
              <div className="MainNavRow">
                <NavBar />
              </div>
              <div className="container-fluid ">
                <div className="cardFull p-4">
                  <div className="row align-items-center justify-content-center pb-20 hdngRowBlk">
                    <div className="col">
                      <div className="MainHdng">
                        <h4>Category List</h4>
                      </div>
                    </div>
                    <div className="col-auto">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search Category"
                      />
                    </div>
                    <div className="col-auto">
                      <div className="prdctsortCol">
                        <div className="form-group">
                          <select ref={selectRef3} className="wide">
                            <option value="Featured">Sort By</option>
                            <option value="10">Active</option>
                            <option value="25">In Active</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-auto">
                      <div className="prdctsortCol">
                        <div className="form-group">
                          <select ref={selectRef2} className="wide">
                            <option value="Featured">Type</option>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-auto">
                      <div className="addPrdctBtn">
                        <Link to="/admin/addcategory" className="btnCommon">
                          <span>
                            <img src={plus} alt="" height="12" />
                          </span>
                          Add
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="hrStyle pb-5">
                    <hr />
                  </div>
                  <div className="prdctListTble common-table">
                    <Table classname="table">
                      <thead>
                        <tr>
                          <th>
                            <input
                              className="form-check-input"
                              type="checkbox"
                            />
                          </th>
                          <th>Image</th>
                          <th>Category</th>
                          <th>Date</th>
                          <th>Status</th>
                          <th align="center" width="150">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {Data?.length > 0 &&
                          Data.map((data, index) => {
                            return (
                              <tr className="whitebgRow">
                                <td>
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                  />
                                </td>
                                <td>
                                  <div className="prdctImg">
                                    <img
                                      src={`${config.config.imgurl}${data.image}`}
                                      alt=""
                                      height="80"
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className="pdrctCat">{data.type}</div>
                                </td>
                                <td>
                                  <div className="SubCat">
                                    {new Date(data.creationDate).toDateString()}
                                  </div>
                                </td>
                                <td className="status">
                                  <Link
                                    onClick={() =>
                                      handleStatus(data._id, data.status)
                                    }
                                    className="btnCommon"
                                  >
                                    {data.status ? "Active" : "InActive"}
                                  </Link>
                                </td>
                                <td className="actions">
                                  <div class="tbl-actn">
                                    <ul>
                                      <li class="edit-btn">
                                        <div class="">
                                          <a
                                            onClick={() =>
                                              navigate("/admin/EditBlogCategory", {
                                                state: {
                                                  id: data._id,
                                                },
                                              })
                                            }
                                          >
                                            <img
                                              src={editIcon}
                                              alt=""
                                              height="18"
                                            />
                                            <span>
                                              <img
                                                src={editWhite}
                                                alt=""
                                                height="18"
                                              />
                                            </span>
                                          </a>
                                        </div>
                                      </li>
                                      <li class="delete-btn">
                                        <div class="">
                                          <a
                                            onClick={() =>
                                              onDeleteCategory(data._id)
                                            }
                                          >
                                            <img
                                              src={deleteIcon}
                                              alt=""
                                              height="18"
                                            />
                                            <span>
                                              <img
                                                src={deleteWhite}
                                                alt=""
                                                height="18"
                                              />
                                            </span>
                                          </a>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </>
  );
}
export default BlogCategoryList;
