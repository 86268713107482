import { Link } from "react-router-dom";
import Button from "../components/Button";


import HeaderImg from '../components/HeaderImg'
import BreadCrumb from '../components/Breadcrumb'
import Text from '../components/Text'
import CustomerService from '../components/CustomerService'
import Newsletter from '../components/Newsletter'

function CycleToWork() {


    return (
        <>
          
           <HeaderImg 
                title="Cycle To Work"
                imgSrc="/imgs/contact.jpg" 
                align="center"
           >
                <BreadCrumb 
                    menuActive="Cycle To Work"
                    align="center"
                />
           </HeaderImg>
            <section className='mt-5'>
                <div className='container'>
                    <div className="row">
                        <div className="col-lg-5">
                           <img src="/imgs/cycleToWork.jpg" />
                        </div>
                        <div className="col-lg-7">
                            <Text
                                    customClass="text-weight-700"
                                    text="Cycle to Work"
                                    variant="36"
                            />
                            <Text
                                customClass="mt-2"
                                text="It is a long established fact that a reader will be distracted by the readable content of a 
                                page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less
                                 normal distribution of letters, as opposed to using 'Content here, content here', making it 
                                look like readable English. Many desktop publishing packages and web page editors now use 
                                Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many 
                                web sites still in their infancy. Various versions have evolved over the years, sometimes by 
                                accident, sometimes on purpose (injected humour and the like)."
                                variant="16"
                            />
                            <Text
                                customClass="mt-3"
                                text="It is a long established fact that a reader will be distracted by the readable content of a 
                                page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less
                                 normal distribution of letters, as opposed to using 'Content here, content here', making it 
                                look like readable English. Many desktop publishing packages and web page editors now use 
                                Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many 
                                web sites still in their infancy. Various versions have evolved over the years, sometimes by 
                                accident, sometimes on purpose (injected humour and the like)."
                                variant="16"
                            />
                             <Button
                                customClass=""
                                text="Contact Us"
                                btnBg="primary mt-5"
                            />
                        </div>
                    </div>
                </div>
            </section>
            
            <section className='my-5'>
                <div className='container'>
                    <div className="row my-3">
                        <div className="col-lg-4">
                            <Text
                                customClass="text-weight-700"
                                text={[<>What is Cycle to <span className="d-inline d-lg-block"> Work?</span></>]}
                                variant="36"
                            />
                        </div>
                        <div className="col-lg-8">  
                            <Text
                                customClass="mt-2"
                                text="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. 
                                The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content 
                                here, content here', making it look like readable English. Many desktop publishing packages and web page editors now 
                                ."
                                variant="16"
                            />
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-lg-12">
                           <img src="/imgs/what-cycle.jpg" />
                        </div>
                    </div>
                </div>
            </section>

            <section className=' cycle-workshop '>
                <div className='container'>
                    <div className="row">
                        <div className="col-lg-7 py-5">
                            <Text
                                    customClass="text-weight-700"
                                    text="Cycle workshop services"
                                    variant="36"
                            />
                            <Text
                                customClass="mt-2"
                                text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been 
                                the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of 
                                type and scrambled it to make a type specimen book. It has survived not only five centuries, but also 
                                the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960
                                with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with 
                                desktop publishing software  like Aldus PageMaker including versions of Lorem Ipsum."
                                variant="16"
                            />
                             <Button
                                customClass=""
                                text="Contact Us"
                                btnBg="primary mt-5"
                            />
                        </div>
                        <div className="col-lg-5">
                            <div className="cycle-workshop-right position-relative ">
                                <img className="position-absolute" src="/imgs/workshop.jpg" />
                           </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='cycle-scheme d-flex align-items-center py-5'>
                <div className='container-fluid'>
                    <div className="row">
                        <div className="col-lg-6 ml-auto text-center">
                            <div className="px-5">
                                <Text
                                        customClass="text-weight-700 text-white"
                                        text="Can anyone use the scheme?"
                                        variant="36"
                                />
                                <Text
                                    customClass="mt-3 text-white"
                                    text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960
                                    with the release of."
                                    variant="16"
                                />
                                <Button
                                    customClass="mt-4"
                                    text="Contact Us"
                                    btnBg="primary"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='py-5'>
                <div className='container'>
                    <div className="row">
                        <div className="col-lg-5">
                           <img src="/imgs/cycle-3.png" />
                        </div>
                        <div className="col-lg-7">
                            <Text
                                    customClass="text-weight-700"
                                    text="What will be the savings I make?"
                                    variant="36"
                            />
                            <Text
                                customClass="mt-2"
                                text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been 
                                the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of 
                                type and scrambled it to make a type specimen book. It has survived not only five centuries, but also 
                                the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960
                                with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with 
                                desktop publishing software  like Aldus PageMaker including versions of Lorem Ipsum."
                                variant="16"
                            />
                            <Text
                                customClass="mt-2"
                                text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been 
                                the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of 
                                type and scrambled it to make a type specimen book. It has survived not only five centuries, but also 
                                the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960
                                with the release of."
                                variant="16"
                            />
                             <Button
                                customClass=""
                                text="Contact Us"
                                btnBg="primary mt-5"
                            />
                        </div>
                       
                    </div>
                    <div className="row">
                        <div className="col-lg-7">
                            <Text
                                    customClass="text-weight-700"
                                    text="What can be put onto the scheme?"
                                    variant="36"
                            />
                            <Text
                                customClass="mt-2"
                                text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been 
                                the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of 
                                type and scrambled it to make a type specimen book. It has survived not only five centuries, but also 
                                the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960
                                with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with 
                                desktop publishing software  like Aldus PageMaker including versions of Lorem Ipsum."
                                variant="16"
                            />
                            <Text
                                customClass="mt-2"
                                text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been 
                                the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of 
                                type and scrambled it to make a type specimen book. It has survived not only five centuries, but also 
                                the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960
                                with the release of."
                                variant="16"
                            />
                             <Button
                                customClass=""
                                text="Contact Us"
                                btnBg="primary mt-5"
                            />
                        </div>
                        <div className="col-lg-5">
                           <img src="/imgs/cycle-4.jpg" />
                        </div>
                    </div>
                </div>
            </section>

            <section className='my-5'>
                <div className='container'>
                    <div className="row ">
                        <div className="col-lg-10 mx-auto text-center">
                           <Text customClass="text-weight-700" text="What can be put onto the scheme?" variant="36"/>
                           <Text
                                customClass="mt-2"
                                text="Firstly your employer must be registered with a scheme provider. If they are not then we can help set them up onto a scheme, 
                                and offer free advice on what scheme may be best for them."
                                variant="16"
                            />
                           <Text
                                customClass="mt-2"
                                text="Next is the fun bit! You choose what bike and accessories you want. You can do this by visiting our stores where we can advise you correctly on the type and size
                                of bike, and offer you the opportunity to test ride before making any choice; or alternatively you can browse our website. There is normally a £1000 limit on what you can select, 
                                but some companies allow more. Once your choices are made you will know the total value to apply for."
                                variant="16"
                            />
                           <Text
                                customClass="mt-2"
                                text='Next request your "letter of collection" from your provider and sign the hire agreement. Your employer will then authorise your application.'
                                variant="16"
                            />
                           <Text
                                customClass="mt-2"
                                text='Bring your "letter of collection" to the store and you will be able to collect your bike and accessories.'
                                variant="16"
                            />
                           <Text
                                customClass="mt-2"
                                text='Once collected your salary sacrifice will commence.'
                                variant="16"
                            />
                        </div>
                    </div>
                </div>
            </section>

            <section className='bg-gray p-3'>
                <div className='container'>
                    <div className="row">
                        <div className="col-lg-10 mx-auto text-center">
                           <Text customClass="text-weight-700" text="Need more help or information?" variant="36"/>
                           <Text
                                customClass="mt-2 mb-2"
                                text="If you would like to discuss any aspect of the cycle to work scheme then please contact us and we will be happy to help."
                                variant="16"
                            />
                        </div>
                    </div>
                </div>
            </section>

            <CustomerService />
            {/* ======= NewsLetter ======== */}

            <div className="container py-4 mt-4">
                <div  style={{borderTop: "1px solid #ddd"}}></div>
                <div className="row ">
                    <div className={` col-md-6 text-center mx-auto my-4`}>
                        <Newsletter />
                    </div>
                </div>
            </div>

            {/* ======= NewsLetter ======== */}

        </>
    );
}

export default CycleToWork