import React, { useEffect, useState,useRef } from "react";
function AdminStyle() {
     return (
         <div id="root">            
            <link href="../css/admin.css" rel="stylesheet" crossorigin="anonymous"></link> 
            <link href="../css/responsive.css" rel="stylesheet" crossorigin="anonymous"></link> 
            <link href="../css/style.css" rel="stylesheet" crossorigin="anonymous"></link> 
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css" integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3" crossorigin="anonymous" />
    </div>
  );
}
export default AdminStyle;