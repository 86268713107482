import React, { useEffect, useState, useRef } from "react";
import { Link, NavLink, useNavigate} from "react-router-dom";
import HeaderImg from "../components/HeaderImg";
import BreadCrumb from "../components/Breadcrumb";
import BlogCard from "../components/BlogCard";
import Text from '../components/Text'
import axios from "axios";
import { config } from "../config";
import moment from "moment";

function Blogs(props) {
    const navigate = useNavigate();

    const [Data, setData] = useState([]);
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10);
    const [total, setTotal] = useState(0);

    // -----------------------------Get List of Blogs---------------------------------

    // const getAllBlogs = async () => {
    //     try {
    //         const response = await axios.get(`${API.get_blog}?page=${page}&&limit=${limit}`);
    //         const List = response.data.response
    //         setData(List)
    //         setTotal(response.data.TotalCount)
    //     } catch (error) {
    //         // toast(error.response.data.data.message);
    //     }
    // };
    // useEffect(() => {
    //     blogList()
    // }, []);
    // const getDate = (params) => {
    //     const date = moment(params).utc().format('YYYY-MM-DD')
    //     return date
    // }

    const blogList = async () => {
        try {
          const response = await axios.get(
            `${config.siteURL}blog/getAllBlogs`
          );
          const List = response.data.response;
          setData(List)
        } catch (error) {
        }
      };

      useEffect(() => {
        blogList();
      }, []);

      const getDate = (d) => {
        const date = moment(d).utc().format("YYYY-MM-DD");
        return date;
      };
    return (
        <>
            <HeaderImg
                title="Blogs"
                imgSrc="/imgs/contact.jpg"
                align="center"
            >
                <BreadCrumb
                    menuActive="Blogs"
                    align="center"
                />
            </HeaderImg>
            <div className="blog-page py-5">
                <div className="container ">
                    <div className="row">
                        {Data && Data.map((item, i) => {
                            return (
                                <div className="col-lg-4 mb-5"  onClick={() => navigate('/blog-detail', {
                                    state: {
                                        id: item._id,
                                        name:item.type,
                                        description:item.description,
                                        image:item.image,
                                        date:item.creationDate
                                    }
                                })}>
                                    <BlogCard
                                        // imgSrc={item.image ? `${config.imgurl}image/${item.image}` : <></>}
                                        imgSrc={`${config.imgurl}${item.image}`} 
                                        dd= {getDate(item.creationDate)}
                                        // mm="05"
                                        // yy="2022"
                                        views="5"
                                    >
                                        <Text
                                            text={item.type}
                                            variant="18"
                                            customClass=" text-weight-500"
                                        />
                                        <div className='d-flex align-items-center mt-2'>
                                            <i class="fa fa-list mr-2 text-primary" aria-hidden="true"></i>
                                            <span>{item.blogcategories[0].type}</span>
                                        </div>

                                    </BlogCard>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Blogs