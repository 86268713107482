import { Link } from "react-router-dom";
import Button from "../components/Button";


import HeaderImg from '../components/HeaderImg'
import BreadCrumb from '../components/Breadcrumb'
import Text from '../components/Text'
import CustomerService from '../components/CustomerService'
import Newsletter from '../components/Newsletter'

function Direction() {

    return (
        <>
           <HeaderImg 
                title="Direction"
                imgSrc="/imgs/contact.jpg" 
                align="center"
           >
                <BreadCrumb 
                    menuActive="Direction"
                    align="center"
                />
           </HeaderImg>
            <section className='mt-5 '>
                <div className='container '>
                    <div className="row">
                        <div className="col-lg-12">
                            <Text
                                customClass="text-weight-700 mb-2"
                                text="On Your Bike London Directions"
                                variant="36"
                            />
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-7">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4966.79434241191!2d-0.09085852912830131!3d51.50592905575681!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487603508835bc3b%3A0x8f0b1aa2b6c95dd9!2sOn%20Your%20Bike!5e0!3m2!1sen!2sin!4v1665059559295!5m2!1sen!2sin" width="100%" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                        <div className="col-md-5">
                            <Text
                                customClass="text-weight-700"
                                text="On Foot"
                                variant="36"
                            />
                            <Text
                                customClass="mt-2"
                                text="Our London Bridge Shop is now located at London Bridge Walk where Richer Sounds and 
                                the Carphone Warehouse used to be. You will find us on the upper level opposite the 
                                London Underground entrance on Duke Street Hill/Tooley Street. Access can be gained 
                                from London Bridge itself or from the London Bridge Station bus stop near the Shard."
                                variant="16"
                            />
                            <Text
                                customClass="text-weight-700 mt-3"
                                text="By Train"
                                variant="36"
                            />
                            <Text
                                customClass="mt-2"
                                text="The nearest station is London Bridge, which is just a short walk from the upper entrance 
                                by the Shard, or just across the road from the Underground Entrance on Duke Street Hill. "
                                variant="16"
                            />
                        </div>
                    </div>
                </div>
            </section>

            <CustomerService customClass="mt-4"/>
            {/* ======= NewsLetter ======== */}

            <div className="container py-4 mt-4">
                <div  style={{borderTop: "1px solid #ddd"}}></div>
                <div className="row ">
                    <div className={` col-md-6 text-center mx-auto my-4`}>
                        <Newsletter />
                    </div>
                </div>
            </div>

            {/* ======= NewsLetter ======== */}

        </>
    );
}

export default Direction