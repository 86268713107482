import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Upload, Image, Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import uploadIcon from "../../assets/images/uploadIcon.svg";
import config from "../../config";

const UploadImages = ({ image, setImages }) => {
  const [fileUrl, setFileUrl] = useState("");

  const beforeUpload = (file) => {
    console.log("Before upload:", file);
    return false;
  };

  const handleChange = (info) => {
    setImages([info?.file]);
  };

  const handleDelete = () => {
    setImages([]);
  };

  const getBase64 = (file, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    if (image?.length) {
      if (image?.[0]?.uid) {
        getBase64(image?.[0], (base64Data) => {
          setFileUrl(base64Data);
        });
      } else {
        setFileUrl(`${config.config.imgurl}${image?.[0]}`);
      }
    } else {
      setFileUrl("");
    }
  }, [image]);

  return (
    <>
      <div className="row">
        <div className="col-sm-2 text-end">
          <Form.Label>Add Image</Form.Label>
        </div>
        <div className="col">
          <div className="form-group">
            {!fileUrl ? (
              <Upload
                name="image"
                listType="picture-card"
                className="avatar-uploader uplogInrDiv"
                showUploadList={false}
                beforeUpload={beforeUpload}
                onChange={handleChange}
              >
                <div>
                  <img src={uploadIcon} alt="Upload" />
                  <div style={{ marginTop: 8 }}>Upload</div>
                </div>
              </Upload>
            ) : (
              <div style={{ position: "relative" }}>
                <Image
                  className="avatar-uploader uplogInrDiv"
                  src={fileUrl}
                  alt="Uploaded"
                  height={"50%"}
                  width={"50%"}
                />
                <Button
                  type="link"
                  icon={<DeleteOutlined />}
                  style={{ color: "red" }}
                  onClick={handleDelete}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadImages;
