import React from "react";
// import PropTypes from 'prop-types'

import './style.css'

function Card(props) {

  return (
    <div id={props.id} className={`cardBox overflow-hidden position-relative overlay-${props.overlay} ${props.customClass} align-${props.childAlign}`}>
      {/* <div className=""  style={{background: `url(${props.imgSrc}) no-repeat top center/cover`}}></div> */}
      <div className=""><img src={props.imgSrc} className="w-100" /></div>

      <div className={`cardBox-child position-absolute text-white ${props.childClass}`}>
        {props.children}
      </div>

      {/* {props?.childrenBottom
        ? <div className="cardBox-child-bottom">
          {props.childrenBottom}
        </div>

        : ""
      } */}

    </div>

  );
}

export default Card;
