import { Link } from "react-router-dom";
import React, { useState } from "react";
import "./style.css";
import Calendar from "react-calendar";
import Text from "../Text";
import Button from "../Button";
import { useFormik } from "formik";
import config from "../../config";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-calendar/dist/Calendar.css";
function Newsletter() {
  const initialValues = {
    number: "",
    country: "",
    date: "",
    time: "",
    email: "",
  };

  const {
    values,
    errors,
    handleBlur,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    // validationSchema: createContactSchema,
    onSubmit: (values) => {
      console.log(values, "book now call");
      bookCall(values);
    },
  });

  const [value, onChange] = useState(new Date());

  // ++++++++++++++++++++++++enquiry+++++++++++++++++++++++++++++++

  const bookCall = async (value) => {
    console.log(value, "values");
    try {
      const response = await axios.post(
        `${config.config.siteURL}appointment/`,
        value
      );
      setFieldValue("number", "");
      setFieldValue("country", "");
      setFieldValue("date", "");
      setFieldValue("time", "");
      setFieldValue("email", "");
      toast(response.data.data.message);
      // window.location.reload();
    } catch (error) {
      // toast(error.response.data.data.message);
    }
  };

  console.log(values)
  return (
    <>
      <div className={`newsletter text-center mt-2`}>
        <Text
          text="Book"
          variant="26"
          customClass="text-uppercase font-secondary text-weight-500"
        />
        <Text
          text="your Call"
          variant="40"
          color="primary"
          customClass="text-uppercase font-secondary text-weight-500"
        />
        <div class="formbold-main-wrapper">
          <div class="formbold-form-wrapper">
            <form action="https://formbold.com/s/FORM_ID" method="POST">
              <div class="formbold-mb-5">
                <label for="phone" class="formbold-form-label">
                  {" "}
                  Phone Number{" "}
                </label>
                <input
                  type="text"
                  name="number"
                  id="phone"
                  placeholder="Enter your phone number"
                  class="formbold-form-input"
                  value={values.number}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div class="formbold-mb-5">
                <label for="email" class="formbold-form-label">
                  {" "}
                  Email Address{" "}
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Enter your email"
                  class="formbold-form-input"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div class="formbold-mb-5">
                <label for="country" class="formbold-form-label">
                  {" "}
                  Country{" "}
                </label>
                <input
                  type="country"
                  name="country"
                  id="country"
                  placeholder="Enter your country name"
                  class="formbold-form-input"
                  value={values.country}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div class="flex flex-wrap formbold--mx-3">
                <div class="w-full sm:w-half formbold-px-3">
                  <div class="formbold-mb-5 w-full">
                    <label for="date" class="formbold-form-label">
                      {" "}
                      Date{" "}
                    </label>
                    <input
                      type="date"
                      name="date"
                      id="date"
                      class="formbold-form-input"
                      value={values.date}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
                <div class="w-full sm:w-half formbold-px-3">
                  <div class="formbold-mb-5">
                    <label for="time" class="formbold-form-label">
                      {" "}
                      Time{" "}
                    </label>
                    <input
                      type="time"
                      name="time"
                      id="time"
                      class="formbold-form-input"
                      value={values.time}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
              </div>

              <div>
                <Button
                  text="Book Appointment "
                  btnBg="primary"
                  btnOnClick={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                />
                {/* <button class="formbold-btn">Book Appointment</button> */}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Newsletter;
