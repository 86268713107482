import React, { useEffect,useRef } from "react";
import {useLocation,useNavigate } from "react-router-dom";
import {Form} from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Style from "./AdminStyle";
import Sidebar from "./Sidebar";
import NavBar from "./NavBar";
import $ from "jquery";
import axios from "axios";
import config from "../../config";
import { useFormik } from "formik";
import UploadImages from "../Upload";

window.jQuery = window.$ = $;
require("jquery-nice-select");
function EditBlogCategory() {
  const navigate = useNavigate()
  const selectRef1 = useRef();
  useEffect(() => {
    $(selectRef1.current).niceSelect();
  }, []);
  const selectRef2 = useRef();
  useEffect(() => {
    $(selectRef2.current).niceSelect();
  }, []);
  const selectRef3 = useRef();
  useEffect(() => {
    $(selectRef3.current).niceSelect();
  }, []);

  const { state } = useLocation();

  const initialValues = {
    type: "",
    description: "",
    image: [],
  };

  const {
    values,
    errors,
    handleBlur,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {
        editCategory(values)
    },
  });

  const editCategory = async (value) => {
    try {
      const formData = new FormData();
  
      // Append other key-value pairs from the 'value' object to the FormData
      Object.entries(value).forEach(([key, val]) => {
        // If it's an array of files, append each file
        if (Array.isArray(val)) {
          val.forEach((file, index) => {
            formData.append("files", file, file.name);
          });
        } else {
          formData.append(key, val);
        }
      });
  
      const response = await axios.patch(
        `${config.config.siteURL}blog/editBlogCategory/${state.id}`,
        formData
      );
  
      // Uncomment the line below if you want to show a toast message
      // toast(response.data.data.message)
  
      navigate("/admin/BlogCategoryList");
    } catch (error) {
      // Uncomment the line below if you want to show an error toast message
      // toast(error.response.data.data.message)
    }
  };

  const setImages = (file) => {
    setFieldValue("image", file);
  };

  const getCategoryList = async () => {
    try {
      const res = await axios.get(
        `${config.config.siteURL}blog/get_blog_category_by_id?id=${state.id}`
      );
      setFieldValue("type",res.data.data.type) 
      setFieldValue("description",res.data.data.description) 
      setFieldValue("image",res.data.data.image) 
    } catch (error) {}
  };

  useEffect(() => {
    getCategoryList();
  }, []);



  return (
    <>
      <Style />

      <article id="root" className="mainRoot">
        <div className="wrapper">
          <div className="sidebar">
            <Sidebar />
          </div>
          <div className="content-page">
            <div className="content">
              <div className="MainNavRow">
                <NavBar />
              </div>
              <div className="container-fluid  mt-4">
                <div className="cardFull  Bgwhite Shadow radius20 p-4 mx-4">
                  <div className="addPrdctRow">
                    <div className="MainHdng">
                      <h3>Edit Category</h3>
                    </div>
                    <div class="hrStyle pb-5">
                      <hr />
                    </div>
                    <div className="addAcordion">
                      <form class="formStyle addFormStyle" action="#">
                        <div class="row row d-flex align-items-center ">
                          <div className="col-sm-2 text-end">
                            <Form.Label>Category Name </Form.Label>
                          </div>
                          <div class="col">
                            <Form.Control
                              type="text"
                              placeholder="Enter Category Name"
                              value={values.type}
                              name="type"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                        </div>
                        <div class=" mb-15">
                          <div class="row row d-flex align-items-center mt-4">
                            <div className="col-sm-2 text-end">
                              <label class="form-label" for="input5">
                                Description{" "}
                                <span class="contact__form--label__star">
                                  *
                                </span>
                              </label>
                            </div>
                            <div class="col">
                              <div className="ckEditor">
                                <CKEditor
                                  editor={ClassicEditor}
                                  data={values
                                .description}
                                  onReady={(editor) => {
                                    // You can store the "editor" and use when it is needed.
                                    console.log(
                                      "Editor is ready to use!",
                                      editor
                                    );
                                  }}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setFieldValue("description", data);
                                    console.log(values, "taa");
                                  }}
                                  onBlur={(event, editor) => {
                                    console.log("Blur.", editor);
                                  }}
                                  onFocus={(event, editor) => {
                                    console.log("Focus.", editor);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mb-15 mt-4">
                        <UploadImages
                            image={values?.image}
                            setImages={setImages}
                          />
                        </div>
                        <div className="SeoForm">
                          <div className="row d-flex align-items-center my-3 mb-4">
                            <div className="col-sm-2 text-end">
                              <Form.Label>Meta Tag Title </Form.Label>
                            </div>
                            <div class="col">
                              <input
                                type="text"
                                name="product_description[1][meta_title]"
                                placeholder="Meta Tag Title"
                                id="input-meta-title1"
                                class="form-control"
                              />
                            </div>
                          </div>
                          <div className="row d-flex align-items-center my-3  mb-4">
                            <div className="col-sm-2 text-end">
                              <Form.Label>Meta Tag Description </Form.Label>
                            </div>
                            <div class="col">
                              <textarea className="form-control"></textarea>
                            </div>
                          </div>
                          <div className="row d-flex align-items-center my-3 ">
                            <div className="col-sm-2 text-end">
                              <Form.Label>Meta Tag Keywords </Form.Label>
                            </div>
                            <div class="col">
                              <textarea className="form-control"></textarea>
                            </div>
                          </div>
                        </div>
                        <div className="row d-flex align-items-center my-3  mb-4">
                          <div className="col-sm-2 text-end"></div>
                          <div class="col">
                          <button class="btnCommon" type="submit" onClick={(e)=>{
                                e.preventDefault()
                                handleSubmit()
                                }}>
                              Continue
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </>
  );
}
export default EditBlogCategory;
