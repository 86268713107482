
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import HeaderImg from '../components/HeaderImg'
import BreadCrumb from '../components/Breadcrumb'
import Text from '../components/Text'
import Button from '../components/Button'
import Newsletter from '../components/Newsletter'
import config from "../config";
import axios from "axios";

function Service() {

    const navigate = useNavigate();
    const [service, setService] = useState([
        // {id:"s1", img: "/imgs/repair.svg", title:"Search Engine Optimization", descrip: "Semper eget laoreet elementum nibh habitasse ut consectetuer vulputate curae"},
        // {id:"s2", img: "/imgs/repair.svg", title:"Social Media Marketing", descrip: "Semper eget laoreet elementum nibh habitasse ut consectetuer vulputate curae"},
        // {id:"s3", img: "/imgs/repair.svg", title:"Email Marketing", descrip: "Semper eget laoreet elementum nibh habitasse ut consectetuer vulputate curae"},
        // {id:"s4", img: "/imgs/sliderIco1.svg", title:"Pay Per Click Management", descrip: "Semper eget laoreet elementum nibh habitasse ut consectetuer vulputate curae"},
        // {id:"s5", img: "/imgs/repair.svg", title:"Link Building and content", descrip: "Semper eget laoreet elementum nibh habitasse ut consectetuer vulputate curae"},
        // {id:"s6", img: "/imgs/repair.svg", title:"Paid Search Advertising", descrip: "Semper eget laoreet elementum nibh habitasse ut consectetuer vulputate curae"},
     ])

     const listService = async () => {
        try {
            const response = await axios.get(`${config.config.siteURL}service/getAllServices`);
            const List = response.data.data
            // toast(response.data.data.message);
            setService(List)
            // setTotal(response.data.TotalCount)
        } catch (error) {
            // toast(error.response.data.data.message);
        }
    };

    useEffect(() => {
        listService()
    }, []);
    

    return (
        <>
          
           <HeaderImg 
                title="Our Services"
                imgSrc="/imgs/services.png" 
                align="center"
           >
                <BreadCrumb 
                    menuActive="service"
                    align="center"
                />
           </HeaderImg>
            <section className='service mt-5 '>
                <div className='container '>
                    <div className="row">
                        <div className="col-md-6 mx-auto text-center">
                            <Text
                                customClass="font-secondary text-weight-600 text-uppercase"
                                text="Our Services"
                                variant="36"
                            />
                            <Text
                                customClass=""
                                text="We specialize in comprehensive digital marketing solutions, encompassing SEO, social media management, content strategy, lead generation and more, tailored to enhance your brand's online impact and drive results that matter."
                                variant="16"
                            />
                        </div>
                    </div>
                    <div className="row mt-5 ">
                       
                            {service &&
                                service.length > 0 &&
                                service.map((item) => {
                                //   let Url = item.slug;
                                  return (
                            <div className="col-md-4 mb-4">
                                <div className="serviceCard box-shadow h-100 text-center p-5 mt-4 cursor-pointer service"  onClick={() => navigate(`/services/${item.slug}`)} >
                                
                                    <img src={`${config.config.imgurl}${item.image}`}/>
                                    <Text
                                        customClass="font-secondary text-weight-600 text-uppercase my-2 text-primary"
                                        text={item.type}
                                        variant="26"
                                       
                                    />
                                    <Text
                                        text={item.description}
                                        variant="16"
                                    />
                                </div>
                            </div>  );
                              })}
                    </div>
                </div>
            </section>

            {/* <section className='my-5'>
                <div className='container'>
                    <div className='row align-items-center'>
                        <div className='col-lg-5'>
                            <img className='w-100' src='/imgs/service-bike.png' />
                        </div>
                        <div className='col-lg-7'>
                            <Text
                                customClass="text-weight-600 mt-3"
                                text="Bike Repair"
                                variant="26"
                            />
                            <Text
                                customClass=""
                                text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been 
                                the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of 
                                type and scrambled it to make a type specimen book. It has survived not only five centuries, but also 
                                the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960
                                with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with 
                                desktop publishing software  like Aldus PageMaker including versions of Lorem Ipsum."
                                variant="16"
                            />
                            <Text
                                customClass="mt-3"
                                text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been 
                                the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of 
                                type and scrambled it to make a type specimen book. It has survived not only five centuries, but also 
                                the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960
                                with the release of."
                                variant="16"
                            />
                             <Button
                                customClass=""
                                text="Contact Us"
                                btnBg="primary mt-3"
                            />
                        </div>
                    </div>
                    <div className='row align-items-center'>
                        <div className='col-lg-7'>
                            <Text
                                customClass="text-weight-600 mt-3"
                                text="Cycle workshop services"
                                variant="26"
                            />
                            <Text
                                customClass=""
                                text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been 
                                the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of 
                                type and scrambled it to make a type specimen book. It has survived not only five centuries, but also 
                                the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960
                                with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with 
                                desktop publishing software  like Aldus PageMaker including versions of Lorem Ipsum."
                                variant="16"
                            />
                            <Text
                                customClass="mt-3"
                                text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been 
                                the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of 
                                type and scrambled it to make a type specimen book. It has survived not only five centuries, but also 
                                the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960
                                with the release of."
                                variant="16"
                            />
                             <Button
                                customClass=""
                                text="Contact Us"
                                btnBg="primary mt-3"
                            />
                        </div>
                        <div className='col-lg-5'>
                            <img className='w-100' src='/imgs/service-cycle.png' />
                        </div>
                    </div>
                </div>
            </section> */}

            {/* <section className='my-5'>
                <div className='container'>
                    <div className='row align-items-center text-center'>
                        <div className='col-lg-12'>
                            <Text
                                customClass="text-weight-600"
                                text="We are approved service centres for the following:"
                                variant="26"
                            />
                            <Text
                                customClass="text-weight-600"
                                text="Brompton B-spoke Centre"
                                variant="26"
                            />
                            <Text
                                customClass=""
                                text="This is the highest accreditation offered to stores by Brompton. The workshop carries an extensive range of spares, as well as Brompton specific
                                tools. This allows servicing to all frame joints, Sturmey Archer and Sram hubs, in addition to general repairs"
                                variant="16"
                            />
                        </div>
                        <div className='col-lg-12 mt-3'>
                            <img src='/imgs/approve.jpg' />
                        </div>
                    </div>
                </div>
            </section>
             */}
            {/* <section className='my-4 '>
                <div className='container'>
                    <div className='row align-items-center '>
                        <div className='col-lg-6'>
                            <div className='bg-gray-light  px-5 py-4'>
                                <img src='/imgs/shimano.png' />
                                <Text text="Shimano Service Centre" variant="26" customClass="mt-3 text-weight-600"/>
                                <Text 
                                    text="All servicing and warranty work is performed by qualified Shimano 
                                    experts. Alongside offering maintenance and repair work, staff can 
                                    offer advice on Shimano products and innovations that each cyclist 
                                    can rely on." 
                                    variant="16" 
                                    customClass="mt-3"
                                />

                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='bg-gray-light  px-5 py-4'>
                                <img src='/imgs/campagno.png' />
                                <Text text="SCampagnolo EPS" variant="26" customClass="mt-3 text-weight-600"/>
                                <Text 
                                    text="We have up to date training and certification from Velotech to advise on, 
                                    install and repair all v1 and v2 EPS groupsets and components. This is in
                                    addition to all Campagnolo mechanical systems, for which we stock all 
                                    essential service items." 
                                    variant="16" 
                                    customClass="mt-3"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            {/* <section className='my-5'>
                <div className='container'>
                    <div className='row align-items-center'>
                        <div className='col-lg-5'>
                            <img className='w-100' src='/imgs/maintenance.jpg' />
                        </div>
                        <div className='col-lg-7'>
                            <Text
                                customClass="text-weight-600 mt-3"
                                text="Maintenance Classes"
                                variant="26"
                            />
                            <Text
                                customClass=""
                                text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been 
                                the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of 
                                type and scrambled it to make a type specimen book. It has survived not only five centuries, but also 
                                the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960
                                with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with 
                                desktop publishing software  like Aldus PageMaker including versions of Lorem Ipsum."
                                variant="16"
                            />
                            <Text
                                customClass="mt-3"
                                text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been 
                                the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of 
                                type and scrambled it to make a type specimen book. It has survived not only five centuries,
                                with the release of."
                                variant="16"
                            />
                             <Button
                                customClass="mt-4"
                                text="Contact Us"
                                btnBg="primary"
                            />
                        </div>
                    </div>
                    <div className='row align-items-center mt-5'>
                        <div className='col-lg-7'>
                            <Text
                                customClass="text-weight-600 mt-3"
                                text="Doctor Bike"
                                variant="26"
                            />
                            <Text
                                customClass=""
                                text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been 
                                the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of 
                                type and scrambled it to make a type specimen book. It has survived not only five centuries, but also 
                                the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960
                                with the release of Letraset sheets containing Lorem Ipsum passages, and more."
                                variant="16"
                            />
                            <Text
                                customClass="mt-3"
                                text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been 
                                the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of 
                                type and scrambled it to make a type specimen book. It has survived not only five centuries, but also 
                                the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960
                                with the release of."
                                variant="16"
                            />
                             <Button
                                customClass="mt-4"
                                text="Contact Us"
                                btnBg="primary"
                            />
                        </div>
                        <div className='col-lg-5'>
                            <img className='w-100' src='/imgs/doctor.jpg' />
                        </div>
                    </div>
                </div>
            </section> */}
                    
            

            {/* ======= NewsLetter ======== */}

            <div className="container py-4 mt-4">
                <div  style={{borderTop: "1px solid #ddd"}}></div>
                <div className="row ">
                    <div className={` col-md-6 text-center mx-auto my-4`}>
                        <Newsletter />
                    </div>
                </div>
            </div>

            {/* ======= NewsLetter ======== */}

        </>
    );
}

export default Service