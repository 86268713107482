
import React, {children , useState , useEffect} from 'react';
import { NavLink as Link } from "react-router-dom";
import Topbar from '../Topbar'
import Text from '../Text'
import InputGroup from '../Input'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import './style.css'

  function Navbar (props){

    const[firstName,setfirstName]=useState("")
    const[lastName,setlastName]=useState("")
    const[email,setemail]=useState("")

    const checkLogin =()=>{
      const fname = localStorage.getItem('firstName')
      setfirstName(fname)
      const lname = localStorage.getItem('lastName')
      setlastName(lname)
      const mail = localStorage.getItem('email')
      setemail(mail)
      // console.log(name, "local")
    }

    const logout = ()=>{
      localStorage.clear();
      checkLogin()
    }

    useEffect(()=>{console.log("testing",)
     checkLogin()
    })

  return (
    <> 
    <div className='dp-header '>
      
        <div className='dp-navbar bg-black '>
            <ul className="d-flex align-items-center justify-content-around list-group">
               
                <li className='d-flex align-items-center'>
                <div className='header-logo '>
                  <Link to="/" >
                      <img src='/logo.svg' />   
                  </Link>
              </div></li>
                <li className='d-flex align-items-center text-white'><Link to="/"> Home</Link></li>
                <li><Link to="/service"> Sevices</Link></li>
                {/* <li><Link to="/about2"> Our Projects</Link></li> */}
                <li><Link to="/contactus"> Contact Us</Link></li>
                <li><Link to="/testimonials">Testimonials </Link></li>
                <li><Link to="/about"> About Us</Link></li>
                <li><Link to="/blogs"> Blogs</Link></li>
                <li className='d-flex align-items-center'>
                  {/* <Link className="wishlist d-flex align-items-center">
                      <img className='mr-1' src='imgs/heart.svg' /><strong> Wishlist</strong>
                  </Link> */}
                  {/* <Link className="cartMenu ml-5">
                      <img src='imgs/bag.svg' />
                      <span> 2 </span>
                  </Link> */}
                </li>
                <li>
                </li>
            </ul>
        </div>
      </div>
                  
    </>
  );
}

export default Navbar;
